import React from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core";
import Text from "../../textComponent/text.jsx";

const useStyles = makeStyles((theme) => ({
	userIcon: {
		color: "darkgray",
		width: "30px",
		height: "30px",
		margin: "10px",
		cursor: "pointer",
		borderRadius: "100%",
		alignSelf: "center",
	},
	professionalName: {
		fontSize: "15px !important",
		color: "darkgray",
		textAlign: "center",
		width: "100px",
		alignSelf: "center",
		fontFamily: "Poppins",
	},
}));

const ProfessionalSelect = (props) => {
	const classes = useStyles();
	const { name, onClick, selected } = props;

	return (
		<>
			<div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }} onClick={onClick}>
				<AccountCircleIcon
					className={classes.userIcon}
					style={{
						color: selected ? "#3689ea" : "darkgray",
					}}></AccountCircleIcon>
				<Text className={classes.professionalName}>{name?.split(" ")[0]}</Text>
			</div>
		</>
	);
};
export default ProfessionalSelect;
