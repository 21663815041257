import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "utils/isMobile";

export default makeStyles((theme) => {
	return {
		professionalTitleHeader: {
			fontSize: "16px",
			color: "#4E5461",
			fontWeight: "bold",
		},
		profissionalContainer: {
			flex: 1,
			overflow: "auto",
		},
		infoProfessionalContainer: {
			margin: 0,
			padding: 0,
			width: "100%",
			display: "flex",
			flexDirection: "column",
			paddingLeft: "1rem",
			paddingTop: "1rem",
			gap: "1rem",
		},
		textInfoProfessional: {
			fontSize: "16px",
			color: "#4E5461",
			fontWeight: "bold",
		},
		textInfoProfessionalHover: {
			fontSize: "16px",
			color: "#FFFFFF",
			fontWeight: "bold",
		},
		listProfessionalContainer: {
			position: "relative",
			width: "100%",
			height: "calc(100vh - 90px)",
			display: "flex",
			flexDirection: "column",
			overflow: "hidden",
		},
		listProfessionalsDisplay: {
			width: "90%",
			height: "6.4rem",
			display: "flex",
			cursor: "pointer",
		},
		listProfessionals: {
			width: "100%",
			padding: "20px 24px",
			display: "flex",
			cursor: "pointer",
			alignItems: "center",
			justifyContent: "start",
			backgroundColor: "transparent",
			border: "none",
			borderBottom: "1px solid #E0E0E0",
			borderRadius: "12px",
			"&:hover": {
				backgroundColor: "#F3F4F6",
				transition: "all 0.3s ease",
			},
		},
		buttonPlus: {
			position: "absolute",
			bottom: "20px",
			right: "20px",
			marginBottom: isMobile() ? "60px" : " ",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "58px",
			height: "58px",
			backgroundColor: "#3688EB",
			color: "white",
			fontSize: "20px",
			border: "none",
			borderRadius: "100%",
			cursor: "pointer",
			boxShadow: "0 2px 10px rgba(0, 0, 0, 0.4)",
		},
		textContainer: {
			display: "flex",
			width: "50%",
			alignItems: "center",
		},
		// Form
		containerForms: {
			overflowY: "auto",
		},
		formProfessionalContainer: {
			width: "100%",
			backgroundColor: "#EDEEF0",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		formProfessionalHeader: {
			width: "80%",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			marginBottom: "0.5rem",
			marginTop: "1rem",
		},
		buttonDelete: {
			fontSize: "14px",
			fontWeight: "bold",
			color: "red",
		},
		labelText: {
			color: "#686973",
			fontSize: "14px",
		},
		inputContainer: {
			width: "90%",
			display: "flex",
			flexDirection: "column",
		},
		mainContainer: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			gap: "2rem",
			overflowY: "auto",
			overflowX: "hidden",
			height: "calc(100vh - 95px)"
		},
		backgroundColorInput: {
			width: "100%",
			display: "flex",
			backgroundColor: "#FFFFFF",
		},
		buttonContainer: {
			width: "90%", 
			display: "flex", 
			justifyContent: "center",
			marginTop: "1rem", 
			marginBottom: "1rem"
		},
		// Hours
		hoursProfessionalsContainer: {
			width: "100%",
			backgroundColor: "#EDEEF0",
		},
		hoursProfessionalHeader: {
			width: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},
		mainHoursContainer: {
			marginTop: "2rem",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			gap: "1.5rem",
		},
		btnAdd: {
			color: "#3688EB",
		},
		hoursContainer: {
			display: "flex",
			alignItems: "center",
			gap: "0.5rem",
			[theme.breakpoints.down("md")]: {
				gap: "0.25rem",
			},
		},
		hoursLabelText: {
			color: "#686973",
			fontSize: "14px",
			width: "5.5rem",
		},
		inputForm: {
			width: "100%",
			padding: "10px",
			borderRadius: "5px",
		},
		labelError: {
			color: "red",
			fontSize: "12px",
			backgroundColor: "#FDEDED",
			padding: "0.5rem",
			borderRadius: "5px",
			marginTop: "0.5rem",
			borderColor: "red !important",
		},
		inpuFormCheckboxOpeningHours: {
			width: "20px",
			height: "20px",
			cursor: "pointer",
		},
		inputHours: {
			width: "110px",
			padding: "10px",
			color: "#686973",
			border: "1px solid #AAABB7",
			borderRadius: "12px",
			fonSize: "14px",
			textAlign: "center",
			cursor: "pointer",
			[theme.breakpoints.down("md")]: {
				width: "70px",
			},
		},
		addPlus: {
			border: "none !important",
			color: "#3688EB",
			fontSize: "24px",
		},
		intervalContainer: {
			display: "flex",
			justifyContent: "end",
			gap: "2rem",
			marginTop: "6px",
			[theme.breakpoints.down("md")]: {
				width: "0.5rem",
			},
		},
		professionalRegisterContainer: {
			display: "flex",
		},
		alertContainer: {
			position: "fixed",
			bottom: "0px",
			right: "0px",
			padding: "3rem",
			[theme.breakpoints.down]: {
				padding: "1rem",
			},
		},
		formLogoButtonUpload: {
			position: "absolute",
			bottom: "-0.3rem",
			width: "40px",
			height: "40px",
			borderRadius: "50%",
			border: "none",
			backgroundColor: "#3688EB",
			color: "white",
			cursor: "pointer",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		logo: {
			width: isMobile() ? "100px" : "120px",
			height: isMobile() ? "100px" : "120px",
			color: "darkgray",
			borderRadius: "50%",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			display: "flex",
			justifyContent: "end",
			alignItems: "flex-end",
		},
		logoListProfessional: {
			width: "70px",
			height: "70px",
			backgroundColor: "#D9D9D9",
			borderRadius: "50%",
			border: "1px solid #C3C3C3",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			display: "flex",
			justifyContent: "end",
			alignItems: "flex-end",
			margin: "10px",
		},
		formLogo: {
			position: "relative",
			display: "flex",
			alignContent: "center",
			justifyContent: "center",
			width: "100%",
		},
	};
});
