import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Modal, Alert, Stack, DialogContent, DialogActions, DialogContentText } from "@mui/material";
import { Box, Button, Dialog, TextField } from "@material-ui/core";
import { MenuItem } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import SelectClientDialog from "../../../components/selectClientDialog/index.jsx";
import SelectServiceDialog from "../../../components/selectServiceDialog/index.jsx";
import GradientLoading from "../../common/GradientLoading/gradientLoading.jsx";
import { patchEditSchedule, patchDeleteSchedule } from "../../../services/scheduleService.js";
import { moneyRender } from "../../../components/utils";
import { useServicesContext } from "../../schedule";
import Text from "../../textComponent/text.jsx";

import "../../../styles/eventModal/index.scss";
import "../../../styles/schedule/index.scss";
import { useConfig } from "context/config.context.js";

const EditBookingModal = ({ isOpen, onClose, onBack, bookingData, event }) => {
	const { business } = useSelector((store) => store.business);
	
	const { sector } = useSelector((store) => store.sector);

	const { contextServices, setContextServices } = useServicesContext();
	const [open, setOpen] = useState(false);
	const [booking, setBooking] = useState({});
	const [loading, setLoading] = useState(false);
	const [messageData, setMessageData] = useState({ message: "", isError: false });
	const [openServiceDialog, setOpenServiceDialog] = useState(false);
	const [professional, setProfessional] = useState({});
	const [client, setClient] = useState({});
	const [service, setService] = useState({});
	const [date, setDate] = useState("");
	const [time, setTime] = useState("");
	const [isFormValid, setIsFormValid] = useState(false);

	const { handleFeedback } = useConfig();

	const updateService = (updatedService) => {
		const categoryId = updatedService.categoryId;
		if (categoryId && contextServices[categoryId]) {
			setContextServices((prev) => ({
				...prev,
				[categoryId]: prev[categoryId].map((s) => (s.id === updatedService.id ? updatedService : s)),
			}));
		}
	};

	useEffect(() => {
		if (!_.isEmpty(event) && business?.professionals && sector?.length > 0) {
			const start = new Date(_.cloneDeep(event.start));
			const formattedDate = start.toISOString().split("T")[0];
			const formattedTime = start.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit" });

			let auxService = _.cloneDeep(
				event.price !== event.service.price ? { ...event.service, price: event.price } : event.service
			);

			if (!auxService.categoryId) {
				for (const cat of sector) {
					const foundService = cat.categories.find((s) => s.id === auxService.id);
					if (foundService) {
						auxService = { ...auxService, categoryId: cat.id };
						break;
					}
				}
			}

			setClient(_.cloneDeep(event.client));
			setService(auxService);
			setBooking(_.cloneDeep(event));
			setDate(formattedDate);
			setTime(formattedTime);

			updateService(auxService);

			const matchedProfessional = business?.professionals.find((prof) => prof.id === event.professionalId);
			if (matchedProfessional) {
				setProfessional(matchedProfessional);
			}
		} else {
			handleFeedback("Erro", "Ocorreu um erro inesperado, tente novamente mais tarde.", "error");
		}
	}, [business, sector]);

	useEffect(() => {
		if (service.id && service.categoryId && contextServices[service.categoryId]) {
			const updatedService = contextServices[service.categoryId].find((s) => s.id === service.id);
			if (updatedService && !_.isEqual(updatedService, service)) {
				setService(updatedService);
			}
		}
	}, [contextServices, service.id, service.categoryId, sector]);

	const handleSelectService = (selectedService) => {
		setService(selectedService);

		const categoryId = selectedService.categoryId;

		if (categoryId && sector) {
			setContextServices((prev) => ({
				...prev,
				[categoryId]: prev[categoryId].map((s) => (s.id === selectedService.id ? selectedService : s)),
			}));
		} else {
			handleFeedback("Ops!", "Categoria não encontrada para o serviço selecionado.", "info");
		}
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleEditBooking = async () => {
		const start_time = new Date(`${date}T${time}`).toISOString();

		const updateData = {
			bookings: [
				{
					professional_id: professional.id,
					service_id: service.id,
					start_time,
					end_time: new Date(new Date(start_time).getTime() + service?.duration * 60000).toISOString(),
					id: event.id,
					startTime: start_time,
					firstStartTime: start_time,
					price: service.price,
					duration: service.duration,
				},
			],
		};
		try {
			setLoading(true);
			const response = await patchEditSchedule(event?.bookingTransactionId, updateData);
			if (response.status === 200) {
				handleFeedback("Sucesso!", "Agendamento atualizado com sucesso.", "success");
				onClose();
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			}
		} catch (error) {
			console.error("Erro ao salvar agendamento:", error);
			handleFeedback("Ops!", "Não foi possível salvar o agendamento.", "error");
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async () => {
		try {
			setLoading(true);
			const response = await patchDeleteSchedule(event?.id);
			if (response.status === 200) {
				handleFeedback("Sucesso!", "Agendamento excluído com sucesso.", "success");
				onClose();
				window.location.reload();
			}
		} catch (error) {
			handleFeedback("Ops!", "Não foi possível excluir agendamento.", "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		validateForm();
	}, [client, service, professional, date, time]);

	const validateForm = () => {
		if (!_.isEmpty(client) && !_.isEmpty(service) && !_.isEmpty(professional) && !_.isEmpty(date) && !_.isEmpty(time)) {
			setIsFormValid(true);
		} else {
			setIsFormValid(false);
		}
	};

	if (loading) {
		return (
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					width: "100vw",
					height: "100vh",
					backgroundColor: "rgba(0, 0, 0, 0.8)",
					zIndex: 500,
				}}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<GradientLoading />
				</Box>
			</div>
		);
	}

	if (!_.isEmpty(messageData.message)) {
		return (
			<div
				style={{
					position: "fixed",
					top: 0,
					width: "100vw",
					height: "100vh",
					zIndex: 999,
					margin: "16px",
				}}>
				{!messageData.isError && (
					<Stack sx={{ width: "300px" }}>
						<Alert severity="success" onClose={() => setMessageData({ message: "", isError: false })}>
							{messageData.message}
						</Alert>
					</Stack>
				)}
				{messageData.isError && (
					<Stack sx={{ width: "300px" }}>
						<Alert severity="error" onClose={() => setMessageData({ message: "", isError: false })}>
							{messageData.message}
						</Alert>
					</Stack>
				)}
			</div>
		);
	}

	return (
		<Fragment>
			<Modal open={isOpen} onClose={onClose} className="containerModal">
				<Box className="modal">
					<div className="headerModal">
						<ArrowBackIos onClick={onBack} style={{ cursor: "pointer" }} />
						<Text
							style={{ cursor: "pointer" }}
							className="deleteButton"
							onClick={() => {
								handleClickOpen();
							}}>
							Excluir
						</Text>
					</div>
					<div className="formContainer">
						<div>
							<Text>Cliente</Text>
							<Button
								className="selectBlock"
								variant="outlined"
								onClick={() => handleFeedback("Ops!", "Você não pode editar o cliente do agendamento.", "info")}>
								{client ? client.name : "Selecione um cliente"}
							</Button>
						</div>
						<div className="inputTimeContainer">
							<TextField
								className="inputTime"
								value={date}
								variant="outlined"
								onChange={(e) => {
									setDate(e.target.value);
								}}
								type="date"
							/>
							<TextField
								className="inputTime"
								value={time}
								variant="outlined"
								onChange={(e) => {
									setTime(e.target.value);
								}}
								type="time"
							/>
						</div>
						<div>
							<label>Serviço</label>
							<Button
								variant="outlined"
								className="selectBlock"
								endIcon={<ArrowForwardIos />}
								onClick={() => setOpenServiceDialog(true)}>
								{service ? (
									<div className="serviceText">
										<Text>{service?.name ? service?.name : service?.serviceName}</Text>
										<Text>{service?.duration + "m"}</Text>
										<Text>{moneyRender(business, service?.price / 100)}</Text>
									</div>
								) : (
									"Selecione um serviço"
								)}
							</Button>
						</div>
						<div>
							<label>Profissional</label>
							<TextField
								select
								variant="outlined"
								value={professional}
								onChange={(e) => setProfessional(e.target.value)}
								placeholder="Selecione um Profissional"
								fullWidth>
								{business?.professionals.map((prof) => (
									<MenuItem className="serviceText" key={prof.id} value={prof}>
										<Text>{prof.name}</Text>
									</MenuItem>
								))}
							</TextField>
						</div>
						<div>
							<button
								className={`saveButton ${!isFormValid ? "disabled" : ""}`}
								onClick={handleEditBooking}
								disabled={!isFormValid}>
								<Text className="editSaveButton">Salvar</Text>
							</button>
						</div>
					</div>
					<Dialog
						open={openServiceDialog}
						fullScreen
						PaperProps={{
							style: { backgroundColor: "transparent" },
						}}>
						<SelectServiceDialog onClose={() => setOpenServiceDialog(false)} select={handleSelectService} />
					</Dialog>
					<Dialog>
						<SelectClientDialog select={setClient} />
					</Dialog>
					<Dialog open={open} onClose={handleClose}>
						<DialogContent>
							<DialogContentText sx={{ fontFamily: "Poppins" }}>Deseja excluir o agendamento?</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button type="button" onClick={handleDelete}>
								Confirmar
							</Button>
							<Button style={{ color: "red" }} type="button" onClick={handleClose} autoFocus>
								Cancelar
							</Button>
						</DialogActions>
					</Dialog>
				</Box>
			</Modal>
		</Fragment>
	);
};

export default EditBookingModal;
