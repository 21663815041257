import "./styles.scss";
import Text from "../textComponent/text";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import GlobalButton from "../globalButton";
import { isMobile } from "../../utils/isMobile";
import { Menu } from "@material-ui/icons";
import { useBusiness } from "../../context/business.context";

const PageHeader = ({ title }) => {
	const { toggleMenu } = useBusiness();

	return (
		<header className="header-container">
			{isMobile() && <GlobalButton type="icon" icon={<Menu />} className="icon-menu-custom" handleClick={toggleMenu} />}
			<Text>{title}</Text>
			<NotificationsNoneIcon className="icon-notification" />
		</header>
	);
};

export default PageHeader;
