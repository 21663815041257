import React from "react";
import { Button, Typography, Container } from "@material-ui/core";
import CreditsCard from "assets/images/credit-card.png";
import { useLocation, useNavigate } from "react-router";
import Header from "../header";
import { useSelector } from "react-redux";
import { useConfig } from "context/config.context";

const PaymentMethods = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { typeAccess } = useConfig();

	const idPlan = location?.state.id;
	const isCardAndPlanUpdate = location?.state?.isCardAndPlanUpdate;
	const businessInfor = useSelector((state) => state.business);

	const handlePaymentMethodClick = (method) => {
		if (!businessInfor?.business?.address?.postalCode && method === "credit") {
			navigate("/address", { state: { id: idPlan, isCardAndPlanUpdate, method } });
		} else {
			navigate(`/subscription/method/${method}`, { state: { id: idPlan, isCardAndPlanUpdate, method } });
		}
	};

	return (
		<Container
			maxWidth="sm"
			style={{
				background: "#fff",
				borderRadius: 3,
				border: 0,
				color: "white",
				minWidth: "100vw",
				minHeight: "100vh",
				display: "flex",
				textAlign: "center",
				alignItems: "center",
				flexDirection: "column",
			}}>
			<Header />
			<Typography variant="h4" style={{ color: "#585858", fontWeight: "bold", fontSize: "25px", padding: "5% 0px" }}>
				Escolha como você quer pagar
			</Typography>
			<div style={{ display: "flex", flexDirection: "column", minWidth: "50%" }}>
				<Button
					variant="contained"
					onClick={() => handlePaymentMethodClick("credit")}
					style={{
						backgroundColor: "white",
						color: "#585858",
						border: "0.1px solid #959393",
						borderRadius: "15px",
						marginBottom: "8%",
						height: "100px",
					}}>
					<Typography style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						Cartão de crédito
						<img src={CreditsCard} alt="Cartão de crédito" style={{ width: "36%" }} />
					</Typography>
				</Button>

				{typeAccess === "onboarding" && (
					<Button
						variant="contained"
						onClick={() => handlePaymentMethodClick("pix")}
						style={{
							backgroundColor: "white",
							color: "#585858",
							border: "0.1px solid #959393",
							borderRadius: "15px",
							marginBottom: "2%",
							height: "100px",
						}}>
						Pix
					</Button>
				)}
			</div>
		</Container>
	);
};
export default PaymentMethods;
