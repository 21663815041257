import React, { createRef, useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Search } from "@material-ui/icons";
import clsx from "clsx";
import GlobalButton from "../globalButton";
import PageHeader from "../pageHeader/index.jsx";

import { searchSchema, initialSearchForm } from "../../helpers/schemas/listclients-schema";
import ClientSide from "./components/client-side";
import SearchClients from "./components/client-search";
import { getAllClients, getClientsByname } from "../../services/clientService";
import { useSelector } from "react-redux";
import ClientForm from "./components/client-form";
import { isMobile } from "utils/isMobile";

import "./style.css";
import Inputs from "../inputs";

export default function ListClients() {
	const [loading, setLoading] = useState(false);
	const [loadingList, setLoadingList] = useState(false);
	const [endList, setEndList] = useState(false);
	const [selectedClient, setSelectedClient] = useState(null);
	const [listClients, setListClients] = useState([]);
	const [newClient, setNewClient] = useState(false);
	const [page, setPage] = useState(1);
	const { business } = useSelector((store) => store.business);

	const scrollRef = createRef();

	const { handleSubmit, control, watch } = useForm({
		resolver: yupResolver(searchSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: initialSearchForm,
	});

	const searchWatch = watch("search");

	const CLIENTS_PER_PAGE = 10;
	const SCROLL_BOTTOM_OFFSET = 100;

	const onSubmit = (data, clear = true) => {
		setLoading(true);
		const _page = clear ? 1 : page;
		const _clientes = clear ? [] : listClients;

		getClientsByname({
			business_id: business.id,
			per_page: CLIENTS_PER_PAGE,
			page: _page,
			name: data.search,
		})
			.then((response) => {
				if (response.data.length === 0) setEndList(true);
				setListClients([..._clientes, ...response.data]);
				setLoading(false);
				setLoadingList(false);
			})
			.catch((error) => {
				console.log("🚀 ~ onSubmit ~ error", error);
				setLoading(false);
			});
	};

	const loadClients = (clear = true) => {
		setLoading(true);
		const _page = clear ? 1 : page;
		const _clientes = clear ? [] : listClients;

		getAllClients(business.id, CLIENTS_PER_PAGE, _page)
			.then((response) => {
				if (response.data.length === 0) setEndList(true);
				setListClients([..._clientes, ...response.data]);
				setLoading(false);
				setLoadingList(false);
			})
			.catch((error) => {
				console.log("🚀 ~ loadClients ~ error", error);
				setLoading(false);
			});
	};

	const handleUpdateClient = () => {
		onSubmit({ search: searchWatch });
		setSelectedClient(null);
		setNewClient(false);
	};

	const handleNewClient = () => {
		setSelectedClient(null);
		setNewClient(true);
	};

	const handleSelectClient = (data) => {
		setSelectedClient(data);
		setNewClient(false);
	};

	const handleUpdateList = async () => {
		if (!loading && !endList) {
			setPage(page + 1);
			setLoadingList(true);
			if (searchWatch.length === 0) {
				loadClients(false);
			} else {
				onSubmit({ search: searchWatch }, false);
			}
		}
	};

	const handleGetScrollPosition = () => {
		if (
			scrollRef.current &&
			scrollRef.current.scrollTop + scrollRef.current.clientHeight >=
				scrollRef.current.scrollHeight - SCROLL_BOTTOM_OFFSET
		) {
			handleUpdateList();
		}
	};

	useEffect(() => {
		if (searchWatch.length === 0) {
			loadClients(true);
		}
	}, [searchWatch]);

	useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.addEventListener("scroll", handleGetScrollPosition);
		}

		return () => {
			if (scrollRef.current) {
				scrollRef.current.removeEventListener("scroll", handleGetScrollPosition);
			}
		};
	}, [scrollRef]);

	useEffect(() => {
		loadClients();
	}, []);

	return (
		<>
			<PageHeader title="Clientes" />
			<Divider />
			<div className="box">
				<div className={clsx("side-search", { hidden: isMobile() && (selectedClient !== null || newClient) })}>
					<div className="form-search">
						<form onSubmit={handleSubmit(onSubmit)} className="input-search-icon">
							<Inputs
								type="text"
								placeholder="Busca por nome ou telefone"
								label="Buscar clientes"
								control={control}
								name="search"
								style={{
									width: "100%",
									padding: "0 1rem",
								}}
								error={false}
							/>
							<button type="submit" className="btn-search">
								<Search style={{ fontSize: "24px", color: "#4E5461" }} />
							</button>
						</form>
					</div>
					<div className="list-clients" ref={scrollRef}>
						{loading && !loadingList ? (
							<div className="loading">
								<AutorenewIcon style={{ fontSize: 36 }} className="animate-spin" />
							</div>
						) : (
							<SearchClients
								data={listClients}
								search={searchWatch}
								selectedClient={selectedClient}
								setSelectedClient={handleSelectClient}
							/>
						)}

						{loadingList && (
							<div className="loading">
								<AutorenewIcon style={{ fontSize: 36 }} className="animate-spin" />
							</div>
						)}
					</div>
					<GlobalButton
						type="button-plus"
						className="btn-add-plus btn-add-plus-color-bg-default btn-add-plus-color-default"
						handleClick={handleNewClient}
					/>
				</div>

				{selectedClient && (
					<ClientSide
						data={selectedClient}
						handleBlockUpdate={() => handleUpdateClient()}
						updateClientsList={() => onSubmit({ search: searchWatch })}
						closeClient={() => setSelectedClient(null)}
					/>
				)}
				{newClient && <ClientForm handleUpdate={() => handleUpdateClient()} closeForm={() => setNewClient(false)} />}
			</div>
		</>
	);
}
