import useStyles from "./styles";
import ListProfessionals from "./components/listProfessionals";
import FormProfessionals from "./components/formProfessionals";
import { Divider } from "@mui/material";
import Services from "../services";
import { isMobile } from "../../utils/isMobile";
import { useState } from "react";
import PageHeader from "../pageHeader";

const Professionals = () => {
	const classes = useStyles();

	const [visibleService, setVisibleService] = useState(false);
	const [isProfessional, setIsProfessional] = useState(false);
	const [selectedService, setSelectedService] = useState([]);
	const [serviceEdit, setServiceEdit] = useState([]);
	const [professionalSelected, setProfessionalSelected] = useState(null);
	const [activeClear, setActiveClear] = useState(false);

	const handleVisibleToService = (visible) => {
		setVisibleService(visible);
		setIsProfessional(visible);
	};

	const selectedServicesToProfessionals = (data) => {
		setSelectedService([...data]);
		setVisibleService(false);
	};

	const selectedServiceEdit = (data) => {
		setServiceEdit([...data]);
	};

	const getProfessionalInfo = (data) => {
		setProfessionalSelected(data);
		setActiveClear(false);
		setSelectedService([]);
		setIsProfessional(true);
	};

	const clear = (data) => {
		setActiveClear(data);
		setProfessionalSelected(null);
		setIsProfessional(true);
	};

	const visibleList = () => {
		setIsProfessional(false);
	};

	const returnFormProfessional = () => {
		setVisibleService(false);
	};

	return (
		<>
			<div style={{ display: visibleService ? "none" : "block" }}>
				<div className={classes.profissionalContainer}>
					{/* Header */}
					<PageHeader title="Profissionais"/>
					<Divider />
					<div className={classes.professionalRegisterContainer}>
						{isMobile() ? (
							<div
								style={{
									display: !isProfessional ? "block" : "none",
									width: isMobile() ? "100%" : "30%",
								}}>
								<ListProfessionals getProfessionalInfo={getProfessionalInfo} clearForm={clear} />
							</div>
						) : (
							<ListProfessionals getProfessionalInfo={getProfessionalInfo} clearForm={clear} />
						)}

						{isMobile() ? (
							<div
								style={{
									display: isMobile() && isProfessional ? "" : "none",
									width: isMobile() ? "100%" : "70%",
								}}>
								<FormProfessionals
									handleVisibleToService={handleVisibleToService}
									visibleService={visibleService}
									servicesPerfomed={selectedService}
									professionalSelected={professionalSelected}
									getProfessionalInfo={getProfessionalInfo}
									activeClear={activeClear}
									selectedServicesToProfessionals={selectedServicesToProfessionals}
									selectedServiceEdit={selectedServiceEdit}
									clear={clear}
									visibleList={visibleList}
								/>
							</div>
						) : (
							<FormProfessionals
								handleVisibleToService={handleVisibleToService}
								visibleService={visibleService}
								servicesPerfomed={selectedService}
								professionalSelected={professionalSelected}
								getProfessionalInfo={getProfessionalInfo}
								activeClear={activeClear}
								selectedServicesToProfessionals={selectedServicesToProfessionals}
								selectedServiceEdit={selectedServiceEdit}
								clear={clear}
								visibleList={visibleList}
							/>
						)}
					</div>
				</div>
			</div>
			{visibleService && (
				<Services
					isProfessional={isProfessional}
					selectedServicesToProfessionals={selectedServicesToProfessionals}
					servicesEditProfessional={serviceEdit}
					returnFormProfessional={returnFormProfessional}
				/>
			)}
		</>
	);
};

export default Professionals;
