import api from "./api";

export const getClients = (businessId, myProfessionalId) => {
	return api.get(
		"client?business_id=" +
			businessId +
			"&per_page=9999&page=1&field=name&order=asc" +
			(myProfessionalId ? "&professional_id=" + myProfessionalId : "")
	);
};

export const getAllClients = (businessId, per_page, page) => {
	return api.get(`client?business_id=${businessId}&per_page=${per_page}&page=${page}&field=name&order=asc`);
};

export const getClientsByname = (data) => {
	return api.get(
		`client?business_id=${data.business_id}&per_page=${data.per_page}&page=${data.page}&&name=${data.name}&field=name&order=asc`
	);
};

export const getClientBooking = (data) => {
	return api.get(
		`booking?business_id=${data.business_id}&client_id=${data.client_id}&per_page=${data.per_page}&page=${data.page}`
	);
};

export const postClient = (body) => {
	return api.post("client", body);
};

export const putClient = (body) => {
	return api.patch(`client/${body.id}`, body);
};

export const updateClientStatus = (clientId, value) => {
	return api.put(`client/${clientId}/active`, { active: value });
};

export const deleteClientStatus = (clientId) => {
	return api.delete(`client/${clientId}`);
};

const clientService = {
	getClients,
	postClient,
};

export default clientService;
