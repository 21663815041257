import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';

export default function ClickableChips({help,customStyle}) {

  const handleNewTab = () => {
    window.open('https://api.whatsapp.com/send?phone=5515996724629&text=Oie!%20Preciso%20de%20ajuda%20para%20completar%20meu%20cadastro');
  };

  return (
    <Stack direction="row" spacing={1}>
      {help && 
        <Chip 
        label={'Precisa de Ajuda?'} 
        onClick={handleNewTab}
        onDelete={()=>{handleNewTab();}}
        size='small'
        style={{ backgroundColor: '#3689EA', color: '#fff', borderRadius: '0.5rem', fontWeight: 300, alignItems: 'center', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px', ...customStyle }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#5A9FE6";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "#3689EA";
        }}
        deleteIcon={<ContactSupportOutlinedIcon style={{color: '#fff'}} />}
        />
      }
    </Stack>
  );
}
