import _ from 'lodash';

import {
  createFeatureTree,
  createFeatureGroups,
  visitTree,
  updateServiceSelectionVisitor,
  findNode,
} from '../../utils/serviceTree';

// ACTION TYPES

const RESET_SERVICES = 'BUSINESS_SERVICES_RESET_SERVICES_REDUCER';
const EXPAND_SERVICE = 'BUSINESS_SERVICES_EXPAND_SERVICES_REDUCER';
const SELECT_SERVICE = 'BUSINESS_SERVICES_SELECT_SERVICES_REDUCER';
const SET_EDIT_SERVICE = 'BUSINESS_SERVICES_SET_EDIT_SERVICES_REDUCER';
const SET_EDIT_GROUP = 'BUSINESS_SERVICES_SET_EDIT_GROUP_REDUCER';
const UPDATE_ITEM_SERVICE = 'BUSINESS_SERVICES_UPDATE_ITEM_SERVICES_REDUCER';
const UPDATE_ITEM_GROUP = 'BUSINESS_SERVICES_UPDATE_ITEM_GROUP_REDUCER';

const initialState = {
  items: [], //ou null
  groups: [], //ou null
  editService: null,
  editGroup: null,
  // ... outras propriedades
};

export const businessServicesSelectReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case RESET_SERVICES: {
      const {
        categories,
        services,
        businessServices,
        professionalServices,
        onboard,
      } = action.payload;

      const items = _.map(categories, (category) => { // Realiza ordenação por level
        const features = _.chain(category.features)
          .filter(feature => (
            feature.featureItems.length > 0
          ))
          .orderBy('level')
          .value();
        
        const featuresMap = _.keyBy(features, 'id'); // Transforma o indice nos ids da feature

        return createFeatureTree(category, features, featuresMap, onboard);
      });

      const groups = _.flatMap(categories, (category) => {
        const features = _.chain(category.features)
          .filter(feature => (
            feature.featureItems.length > 0
          ))
          .orderBy('level')
          .value();
        return createFeatureGroups(features, onboard);
      });

      let newState = {
        ...state,
        items,
        groups,
      };

      // Assign services and business services

      const serviceMap = _.keyBy(services, x => (
        `${x.categoryId}|${x.featureItems.join('|')}`
      ));

      const businessServiceMap = _.keyBy(businessServices, ({ service }) => service.id);
      const professionalServicesMap = _.keyBy(professionalServices || [],
        ({ service }) => service.id);

      const visitor = (node, nodePath) => {
        const serviceKey = nodePath.join('|');
        const service = serviceMap[serviceKey];

        // In case we have a professional service
        // Overwrite all properties from the service
        if (service && professionalServicesMap[service.id]) {
          const businessService = businessServiceMap[service.id];
          const professionalService = professionalServicesMap[service.id];

          const { price, duration, pricingTypeId } = professionalService;

          return {
            ...node,
            selected: true,
            showing: true,
            service: {
              ...service,
              businessService,
              professionalService,
              price,
              duration,
              pricingTypeId,
            },
          };
        }

        // In case we have a business service
        // Overwrite all properties from the service

        if (service && businessServiceMap[service.id]) {
          const businessService = businessServiceMap[service.id];
          const { price, duration, pricingTypeId } = businessService;
          let selected = false;
          let showing = false;
          if (Array.isArray(professionalServices)) {
            showing = true;
            if (!professionalServices.length) {
              selected = true;
            }
          } else {
            selected = !professionalServices;
            showing = !!selected;
          }

          return {
            ...node,
            selected,
            showing,
            service: {
              ...service,
              businessService,
              price,
              duration,
              pricingTypeId,
            },
          };
        }

        if (service) {
          return {
            ...node,
            service,
          };
        }

        return node;
      };

      const updateSelectionVisitor = updateServiceSelectionVisitor();

      newState = visitTree(newState, visitor);
      newState = visitTree(newState, updateSelectionVisitor);

      return newState;
    }

    case EXPAND_SERVICE: {
      const path = action.payload;

      const visitor = (node, nodePath) => {
        // Toggle expanded flag on selected node
        if (_.isEqual(path, nodePath)) {
          return {
            ...node,
            expanded: !node.expanded,
          };
        }
        return node;
      };

      return visitTree(state, visitor);
    }

    case SELECT_SERVICE: {
      const path = action.payload;

      // Don't do anything if it is the root level
      if (path.length === 1) {
        return state;
      }

      const selectedNode = findNode(state, path);
      const selected = !selectedNode.selected;

      const visitor = updateServiceSelectionVisitor(path, selected);

      return visitTree(state, visitor);
    }

    case SET_EDIT_SERVICE: {
      const path = action.payload;
      const item = findNode(state, path);
      return {
        ...state,
        editService: {
          item,
          path,
        },
      };
    }

    case SET_EDIT_GROUP: {
      const path = action.payload;
      const item = findNode(state, path);

      const group = _.find(state.groups, x => x.feature.id === item.featureId);

      return {
        ...state,
        editGroup: _.cloneDeep(group),
      };
    }

    case UPDATE_ITEM_GROUP: {
      const { featureItemId, featureGroupId } = action.payload;
      const group = state.editGroup;

      // Find item
      const featureItem = _.chain(group.items)
        .flatMap('featureItems')
        .find({ id: featureItemId })
        .value();

      const items = _.map(group.items, (featureGroup) => {
        // Remove from previous group
        let featureItems = _.filter(featureGroup.featureItems, { id: featureItemId });

        // Move to new group
        if (featureGroupId === featureGroup.id) {
          featureItem.featureItemGroups = featureGroup.featureGroupValues.map(({ id }) => ({
            featureGroupValueId: id,
          }));
          featureItems = _.chain().concat([featureItem]).orderBy('name').value();
        }

        return {
          ...featureGroup,
          featureItems,
        };
      });

      return {
        ...state,
        editGroup: {
          ...group,
          items,
        },
      };
    }

    case UPDATE_ITEM_SERVICE: {
      const { path, values } = action.payload;

      const visitor = (node, nodePath) => {
        // Update service values within item
        if (_.isEqual(path, nodePath)) {
          return {
            ...node,
            service: {
              ...node.service,
              ...values,
            },
          };
        }
        return node;
      };

      return visitTree(state, visitor);
    }

    case 'UPDATE_ITEMS_SERVICES': {
      return action.payload;
    }


    default:
      return state;
  }
};

// ACTIONS

export const resetServices = (
  categories = [],
  services = [],
  businessServices = [],
  professionalServices = null,
  onboard = false,
) => (dispatch) => {
  dispatch({
    type: RESET_SERVICES,
    payload: {
      categories,
      services,
      businessServices,
      professionalServices,
      onboard,
    },
  });
};

export const selectService = path => (dispatch) => {
  dispatch({
    type: SELECT_SERVICE,
    payload: path,
  });
};

export const setEditService = path => (dispatch) => {
  dispatch({
    type: SET_EDIT_SERVICE,
    payload: path,
  });
};

export const setEditGroup = path => (dispatch) => {
  dispatch({
    type: SET_EDIT_GROUP,
    payload: path,
  });
};

export const updateItemGroup = (featureItemId, featureGroupId) => (dispatch) => {
  dispatch({
    type: UPDATE_ITEM_SERVICE,
    payload: {
      featureItemId,
      featureGroupId,
    },
  });
};

export const updateItemService = (path, values) => (dispatch) => {
  dispatch({
    type: UPDATE_ITEM_SERVICE,
    payload: {
      path,
      values,
    },
  });
};

export const extractBusinessServices = (items) => {
  const services = [];

  const visitor = (node) => {
    if (node.selected && node.service) {
      const {
        id,
        duration,
        price,
        pricingTypeId,
      } = node.service;

      services.push({
        serviceId: id,
        duration,
        price,
        pricingTypeId,
      });
    }
    return node;
  };

  visitTree({ items }, visitor);

  return services;
};

export const filterSelected = (items) => {
  const r = items.filter((i) => {
    if (Array.isArray(i.items)) {
      i.items = filterSelected(i.items);
    }
    return i.showing === true;
  });
  return r;
};

export const updateItemsServices = (newItemsServices) => ({
  type: 'UPDATE_ITEMS_SERVICES',
  payload: newItemsServices,
});

export default businessServicesSelectReducer;