import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as Logo } from "../../../src/assets/images/Boneco31.svg";
import CoreButton from "../common/new/button";
import CoreContainer from "../common/new/container";
import "./index.scss";
import Text from "../textComponent/text";

import { useConfig } from "context/config.context";

const Welcome = () => {
	const navigate = useNavigate();

	const { setTypeAccess } = useConfig();

	useEffect(() => {
		setTypeAccess("schedule");
		localStorage.clear();
	}, []);

	return (
		<CoreContainer style={{flex: "wrap", overflow: "auto"}}>
			<div className="circleBox">
				<div className="imgContainer">
					<Logo />
				</div>
				<div className="circleBgForMobile"></div>
				<div className="contentContainer">
					<Text variant="title">Bem-vindo ao Prit!</Text>
					<Text align="center">
						O app que vai revolucionar a forma que você faz e recebe seus agendamentos.
					</Text>
				</div>
			</div>

			<div className="buttonContainer">
				<CoreButton
					text="Criar conta"
					onClick={() =>
						navigate("/login", {
							state: {
								newBusiness: true,
							},
						})
					}
				/>
				<CoreButton
					invertColors
					text="Já sou cadastrado!"
					onClick={() =>
						navigate("/login", {
							state: {
								newBusiness: false,
							},
						})
					}
				/>
			</div>
		</CoreContainer>
	);
};

export default Welcome;
