import React from "react";
import { Typography } from "@material-ui/core";
import GlobalButton from "../globalButton";
import Text from "../textComponent/text";

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import CoreContainer from "../common/new/container";
import { useConfig } from "context/config.context";

const CurrentPlan = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const { typeAccess } = useConfig();

	const card = location?.state?.card;
	const cancel = location?.state?.cancel;

	const subscriptionCurrent = useSelector((state) => state.subscription_current.subscription_current);

	const expirationDate = subscriptionCurrent ? new Date(subscriptionCurrent.expiration) : new Date();
	const formattedDate = format(expirationDate, "dd MMMM yyyy", { locale: ptBR });

	const style = {
		p: 0,
		width: "100%",
		borderRadius: 2,
		border: "1px solid",
		borderColor: "divider",
		backgroundColor: "background.paper",
		margin: "30% 0px 5% 0px",
	};

	const handleSubscriptionChange = () => {
		navigate("/subscription/onboarding", { state: { isCardAndPlanUpdate: true, view: true } });
	};

	const cardCreditChange = () => {
		navigate("/subscription/method/credit", { state: { cardUpdate: true } });
	};

	const handleDetailsCard = () => {
		navigate("/subscription/details");
	};

	const handleCancel = () => {
		navigate("/subscription/cancelar");
	};

	return (
		<CoreContainer>
			{/* <Header /> */}
			<Text variant={"title"} style={{ color: "#393762" }}>
				{" "}
				Seu plano atual: {subscriptionCurrent.current_plan}
			</Text>

			{!card?.is_recurrence ? (
				<>
					{/* <Typography variant="subtitle2" style={{ color: "#585858", fontSize: "16px", padding: "30% 0px 5% 0px" }}>
						{cancel ? "Você cancelou seu plano" : `Plano atual: ${subscriptionCurrent.current_plan}`}
					</Typography> */}

					<Text style={{ color: "#585858", padding: "0% 0px 10% 0px" }}>
						{cancel ? `Seu plano estará ativo até o dia ${formattedDate}` : `Seu plano expira em  ${formattedDate}`}
					</Text>
					
					<div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}>
						<GlobalButton 
							title={cancel ? "Contratar Plano" : "Outros Planos"}
							type="button"
							variant="contained"
							handleClick={() => navigate("/subscription/onboarding", { state: { view: true } })} 
							className="btn-default btn-color-primary"
						/>
						
						<GlobalButton 
							title="Acessar Agenda"
							type="button"
							variant="outlined"
							handleClick={() => navigate("/schedule")} 
							className="btn-default btn-color-primary-reverse"
						/>
					</div>
				</>
			) : (
				<List sx={style} aria-label="mailbox folders">
					<ListItem>
						<Typography variant="subtitle2" style={{ color: "#585858", fontSize: "16px" }}>
							Plano atual:{" "}
							<span style={{ color: "#393662", cursor: "pointer", fontWeight: "bold" }}>
								{" "}
								{subscriptionCurrent.current_plan}{" "}
							</span>
							<Typography>
								Sua próxima data de cobrança é em{" "}
								<span style={{ color: "#393662", cursor: "pointer", fontWeight: "bold" }}> {formattedDate} </span>{" "}
							</Typography>
						</Typography>
					</ListItem>
					<Divider component="li" />
					<ListItem>
						<Typography variant="subtitle2" style={{ color: "#585858", fontSize: "16px" }}>
							Cartão de crédito terminado em •••• {subscriptionCurrent.card_last_digits}
							<Typography onClick={cardCreditChange}>
								<span style={{ color: "blue", cursor: "pointer", fontWeight: "bold", textDecoration: "underline" }}>
									{" "}
									Editar{" "}
								</span>{" "}
							</Typography>
						</Typography>
					</ListItem>
					<Divider component="li" />
					<ListItem>
						<Typography
							onClick={handleDetailsCard}
							variant="subtitle2"
							style={{ color: "#585858", fontSize: "16px", textDecoration: "underline" }}>
							{" "}
							Detalhes da cobrança{" "}
						</Typography>
					</ListItem>
					<Divider component="li" />
					<ListItem>
						<Typography
							onClick={handleSubscriptionChange}
							variant="subtitle2"
							style={{ color: "#585858", fontSize: "16px", textDecoration: "underline" }}>
							{" "}
							Alterar plano de assinatura{" "}
						</Typography>
					</ListItem>
					<Divider component="li" />
					<ListItem>
						<Typography
							onClick={handleCancel}
							variant="subtitle2"
							style={{ color: "#585858", fontSize: "16px", textDecoration: "underline" }}>
							{" "}
							Cancelar assinatura{" "}
						</Typography>
					</ListItem>
				</List>
			)}
		</CoreContainer>
	);
};
export default CurrentPlan;
