import api from "./api";

export const getMe = () => {
	return api.get("me");
};

const meService = {
	getMe,
};

export default meService;