import React, { useEffect, useState } from "react";
import { TextField, Dialog } from "@material-ui/core";
import CoreContainer from "../common/new/container";
import { AccountCircle, Add, ArrowBackIos, Search } from "@material-ui/icons";
import { InputAdornment  } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import clientAction from "../../store/actions/clientAction";
import sectorAction from "../../store/actions/sectorAction";
import NewClientDialog from "../newClientDialog";
import Text from "../textComponent/text";
import './selectClientDailog/style.scss';

const SelectClientDialog = (props) => {
	const [searchClients, setSearchClients] = useState("");
	const { clients } = useSelector((store) => store.clients);
	const { business } = useSelector((store) => store.business);
	const dispatch = useDispatch();
	const [openClientModal, setOpenClientModal] = useState(false);
	useEffect(() => {
		if (business) {
			dispatch(clientAction.getClients(business?.id, "myProfessionalId"));
			dispatch(sectorAction.getSector(business?.id));
		}
	}, [business]);
	return (
		<CoreContainer style={{ gap: "16px" }} transparent>
			<div style={{ display: "flex" }}>
				<ArrowBackIos style={{ width: "30px", color: "#616161", cursor: 'pointer' }} onClick={props.onClose} />
				<Text style={{ fontWeight: "500", marginTop: "0.2rem" }}>
					Cliente
				</Text>
			</div>

			<div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
				<TextField
					variant="outlined"
					onChange={(e) => setSearchClients(e.target.value)}
					placeholder="Buscar clientes"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search style={{ cursor: "pointer" }} />
							</InputAdornment>
						),
					}}
					style={{ width: "100%" }}
				/>
			</div>

			<div
				style={{ display: "flex", justifyContent: "center", cursor: "pointer", alignItems: "center", gap: "0.4rem" }}
				onClick={() => setOpenClientModal(true)}>
				<Add style={{ color: "#3E3E3E" }} />
				<Text>Adicionar novo cliente</Text>
			</div>
			<div className="clientContainer">
				{clients &&
					clients
						.filter((client) => client?.name?.toLowerCase().includes(searchClients.toLowerCase()))
						.map((client) => (
							<div key={client.id} className="clientItem">
								<div 
									onClick={() => {
										props.select(client);
										props.onClose();
									}}
									>
									<div style={{ display: "flex", alignItems: "center" }}>
										<AccountCircle
											style={{
												color: "lightgray",
												borderRadius: "50%",
												width: "50px",
												height: "50px",
											}}
										/>
										<div className="clientDataContainer">
											<Text>{client?.name || ''}</Text>
											<Text className="clientDataPhone">{client.phone}</Text>
										</div>
									</div>
								</div>
							</div>
						))}
			</div>
			<Dialog
				open={openClientModal}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<NewClientDialog onClose={() => setOpenClientModal(false)} onCloseAll={() => {props.onClose();}} businessId={business?.id} selectClient={(client) => {props.select(client);}}></NewClientDialog>
			</Dialog>
		</CoreContainer>
	);
};

export default SelectClientDialog;
