import PropTypes from "prop-types";
import React from "react";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import CreditCardImage from "../../../assets/images/credit-card.png";
import PixImage from "../../../assets/images/payment-pix.png";
import {
	TextPrincipal,
	BoxCredit,
	AuxView,
	TextCreditCard,
	WrapperCreditCardLogo,
	CreditCardLogo,
	IconWrapper,
	BoxPIX,
	TextPIX,
	PixLogo,
	BackButtonContainer,
} from "./styles";
import { usePayment } from "context/payment.context";
import clsx from "clsx";
import { isMobile } from "utils/isMobile";

const PaymentMethod = () => {
	const { setType } = usePayment();

	return (
		<>
			<div className="payment-content">
				<div className="btn-back">
					<BackButtonContainer onClick={() => setType("PritPlan")}>
						<MdChevronLeft color="#585858" size={35} />
						<span>Voltar</span>
					</BackButtonContainer>
				</div>
				<div className={clsx("payment-box", isMobile() && "mobile")}>
					<TextPrincipal>{"Escolha como você quer pagar"}</TextPrincipal>
					<BoxCredit
						onClick={() => {
							setType("AddressPlan");
						}}>
						<AuxView>
							<TextCreditCard>{"Cartão de Crédito"}</TextCreditCard>
							<WrapperCreditCardLogo>
								<CreditCardLogo src={CreditCardImage} alt="Cartões de crédito" />
							</WrapperCreditCardLogo>
						</AuxView>
						<IconWrapper>
							<MdChevronRight color="#B9B9B9" size={35} />
						</IconWrapper>
					</BoxCredit>
					<BoxPIX
						onClick={() => {
							setType("Pix");
						}}>
						<AuxView>
							<div style={{ display: "flex", alignItems: "center" }}>
								<TextPIX>PIX</TextPIX>
							</div>
							<WrapperCreditCardLogo>
								<PixLogo src={PixImage} alt="Pagamento via PIX" />
							</WrapperCreditCardLogo>
						</AuxView>
						<IconWrapper>
							<MdChevronRight color="#B9B9B9" size={35} />
						</IconWrapper>
					</BoxPIX>
				</div>
			</div>
		</>
	);
};

PaymentMethod.propTypes = {
	setType: PropTypes.func.isRequired,
};

export default PaymentMethod;
