import React, { useEffect, useState } from "react";

const CoreProgressBar = ({ progress }) => {
  const [animatedProgress, setAnimatedProgress] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimatedProgress(progress); // Anima o progresso
    }, 100);
    return () => clearTimeout(timeout);
  }, [progress]);

  const containerStyle = {
    width: "100%",
    maxWidth: "230px",
    height: "15px",
    backgroundColor: "#e0e0de",
    borderRadius: "5px",
    overflow: "hidden",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 1px",
  };

  const fillerStyle = {
    height: "100%",
    width: `${animatedProgress}%`,
    background: "linear-gradient(90deg, #39ADC4 0%, #B545E9 100%)",
    transition: "width 0.5s ease-in-out",
    boxShadow: "0px 0px 10px rgba(181, 69, 233, 0.8)",
  };

  const glowEffect = {
    height: "100%",
    width: "100%",
    background: "radial-gradient(circle, rgba(181, 69, 233, 0.3) 30%, transparent 80%)",
    animation: "pulse 1.5s infinite",
  };

  return (
    <div style={containerStyle}>
      <div style={fillerStyle}></div>
      <div style={glowEffect}></div>
    </div>
  );
};

export default CoreProgressBar;
