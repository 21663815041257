import _ from 'lodash';
import moment from 'moment';

export const SERVICES_ONBOARD_FETCH = 'SERVICES_ONBOARD_FETCH';
export const SERVICES_ONBOARD_FETCH_SUCCESS = 'SERVICES_ONBOARD_FETCH_SUCCESS';
export const SERVICES_ONBOARD_FETCH_FAILURE = 'SERVICES_ONBOARD_FETCH_FAILURE';
export const SERVICES_UPDATE_SUCCESS = 'SERVICES_UPDATE_SUCCESS';
export const CATEGORIES_UPDATE_SUCCESS = 'CATEGORIES_UPDATE_SUCCESS';
export const BUSINESS_SERVICES_UPDATE_SUCCESS = 'BUSINESS_SERVICES_UPDATE_SUCCESS';
export const PROFESSIONALS_SERVICES_UPDATE_SUCCESS = 'PROFESSIONALS_SERVICES_UPDATE_SUCCESS';
export const RESET_SERVICES = 'RESET_SERVICES';

const initialState = {
  loading: false,
  error: null,
  services: [],
  categories: [],
  businessServices: [],
  professionalsServices: [],
  professionalsServicesGrouped: [],
  timerServicesUpdate: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICES_ONBOARD_FETCH:
      return {
        ...initialState,
        loading: true,
      };
    case SERVICES_ONBOARD_FETCH_SUCCESS:
      return {
        ...initialState,
        services: action.payload.services,
        categories: action.payload.categories,
      };
    case SERVICES_ONBOARD_FETCH_FAILURE: {
      return {
        ...initialState,
        error: action.payload.error,
      };
    }
    case SERVICES_UPDATE_SUCCESS:
      return {
        ...state,
        services: action.payload.services
      };
    case CATEGORIES_UPDATE_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories
      };
    case BUSINESS_SERVICES_UPDATE_SUCCESS: {
      const services = action.payload.services;
      const setTimerServices = action.payload.setTimerServices;
      return {
        ...state,
        businessServices: services,
        professionalsServices: [],
        professionalsServicesGrouped: [],
        timerServicesUpdate: setTimerServices ? moment().utc().format() : null,
      };
    }
    case PROFESSIONALS_SERVICES_UPDATE_SUCCESS: {
      const services = action.payload.services;
      const resetServices = action.payload.resetServices;
      const setTimerServices = action.payload.setTimerServices;
      const grouped = action.payload.grouped;
      const professional = action.payload.professional;
      let profList = [];
      if (grouped) {
        profList = state.professionalsServices.filter(i => !i.professionalId.includes(professional.id));
        profList = [...profList, { professionalId: professional.id, services: services }];
      } else {
        profList = state.professionalsServicesGrouped.filter(i => !i.professionalId.includes(professional.id));
        if (!resetServices) {
          profList = [...profList, { professionalId: professional.id, services: services }];
        }
      }
      return {
        ...state,
        businessServices: resetServices ? [] : state.businessServices,
        professionalsServices: grouped ? profList : state.professionalsServices,
        professionalsServicesGrouped: !grouped ? profList : state.professionalsServicesGrouped,
        timerServicesUpdate: setTimerServices ? moment().utc().format() : null,
      };
    }
    case RESET_SERVICES:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const flattenTree = (services) =>
  _.flatMapDeep(services, (service) => {
    if (service.items) {
      return flattenTree(service.items);
    }
    return service;
  });

export const resetServices = () => (dispatch) => {
  dispatch({
    type: RESET_SERVICES,
  });
};

export const updateInfoServices = (services = [], categories = [], payloadRoute, resetServices = false, setTimerServices = false, grouped = true, professional = null) => (dispatch) => {
  dispatch({
    type: payloadRoute,
    payload: {
      services,
      categories,
      resetServices,
      setTimerServices,
      grouped,
      professional,
    },
  });
};