import { TextField } from "@material-ui/core";
import React from "react";

const InputTextArea = ({
	label,
	value,
	variant = "outlined",
	type,
	placeholder,
	inputRef,
	handleChange,
	handleKeyDown,
	style,
	inputProps,
	onBlur,
	className,
	minRows = 4,
}) => {
	return (
		<TextField
			multiline
			inputRef={inputRef}
			minRows={minRows}
			value={value}
			variant={variant}
			type={type}
			placeholder={placeholder}
			label={label}
			onChange={handleChange}
			onBlur={onBlur}
			onKeyDown={handleKeyDown}
			style={style}
			inputProps={inputProps}
			className={className}
		/>
	);
};

export default InputTextArea;
