import React from "react";
import { Popover } from "@material-ui/core";
import AndroidOutlinedIcon from "@mui/icons-material/AndroidOutlined";
import AppleIcon from "@mui/icons-material/Apple";
import './index.scss';

const PopoverComponent = ({ anchorEl, setAnchorEl }) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handlePlayStore = () => {
    window.open("https://play.google.com/store/apps/details?id=app.prit.business&hl=pt_BR");
  };
  const handleAppleStore = () => {
    window.open("https://apps.apple.com/br/app/prit-para-profissionais/id1466577398?mt=8");
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{ className: 'glassmorphism'
      }}
    >
      <div style={{ display: "flex", flexDirection: 'column', gap: '0.3rem'}}>
        <button className="buttonPlatform" onClick={handlePlayStore}>
          <AndroidOutlinedIcon style={{color: "#118B50"}}/>
        </button>
        <button className="buttonPlatform" onClick={handleAppleStore}>
          <AppleIcon style={{color: "#81BFDA"}}/>
        </button>
      </div>
    </Popover>
  );
};

export default PopoverComponent;
