import React from "react";
import CoreContainer from "../common/new/container";
import { Close } from "@material-ui/icons";
import { Button, Dialog as DialogDefault } from "@mui/material";
import Text from "../textComponent/text";

const Dialog = ({ isOpen, handleFirstBtn, handleSecondBtn, title, lblBtnFirst, lblBtnSecond, colorBtnSecond }) => {
	const handleConfirmLogout = () => {
		handleFirstBtn();
	};

	return (
		<DialogDefault
			onClose={handleSecondBtn}
			open={isOpen}
			fullScreen
			PaperProps={{
				style: { backgroundColor: "transparent" },
			}}>
			<CoreContainer style={{ gap: "16px" }} transparent noHeight>
				<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
					<Text style={{color: "#616161" }}>{title}</Text>
					<Close style={{ color: "#3E3E3E", cursor: "pointer" }} onClick={() => handleSecondBtn()}></Close>
				</div>
				<div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
					<Button
						variant="outlined"
						style={{
							backgroundColor: colorBtnSecond ?? "#3688EB",
							color: "#FFF",
							borderRadius: "8px",
							width: "50%",
							textTransform: "none",
							fontFamily: 'Poppins'
						}}
						onClick={() => handleConfirmLogout()}>
						{lblBtnFirst ?? "Sim"}
					</Button>

					<Button
						variant="outlined"
						style={{
							backgroundColor: "transparent",
							color: "#3688EB",
							borderRadius: "8px",
              border: "1px solid #3688EB",
							width: "50%",
							textTransform: "none",
							fontFamily: 'Poppins',
						}}
						onClick={() => handleSecondBtn()}>
						{lblBtnSecond ?? "Não"}
					</Button>
				</div>
			</CoreContainer>
		</DialogDefault>
	);
};

export default Dialog;
