import React from "react";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";

const ButtonCommom = (props) => {
	const { toLogin, text, toCode, toData, toWelcome, toSchedule, onClick, id, params, disable, invertColors, style } =
		props;

	const navigate = useNavigate();

	const handleClick = () => {
		if (!disable) {
			if (onClick) {
				onClick();
			}
			if (toLogin) {
				navigate("/login", { state: params });
			} else if (toCode) {
				navigate("/login/code", { state: params });
			} else if (toData) {
				navigate("/login/data", { state: params });
			} else if (toWelcome) {
				navigate("/welcome", { state: params });
			} else if (toSchedule) {
				navigate("/subscription/onboarding", { state: params });
			}
		}
	};

	return (
		<Button
			id={id}
			onClick={handleClick}
			style={{
				fontSize: "calc(1vw + 8px)",
				marginBottom: "55px",
				padding: "7px 40px",
				borderRadius: "15px",
				backgroundColor: disable ? "gray" : invertColors ? "#fff" : "#4A90E2",
				border: "solid 1px #fff",
				color: invertColors ? "#393762" : "#fff",
				cursor: disable ? "unset" : "pointer",
				overflow: "hidden",
				width: "100%",
				...style,
			}}
			variant="outlined">
			{text ? text : "Avançar"}
		</Button>
	);
};
export default ButtonCommom;
