// Based on this gist: https://gist.github.com/ChrisJefferson/cb8db2a4c67a9506c56c
// JS Bin: https://jsbin.com/cefopi/edit?js,console
import _ from 'lodash';

const nestedCombinations = (rest) => (
  _.reduce(rest, (a, b) => (
    _.flatMap(a, x => (
      _.map(b, y => (
        x.concat([y])
      ))
    ))
  ), [[]])
);

export default nestedCombinations;

