import api from "./api";

const getBookingTransaction = (bookingTransactionId) => {
	return api.get("booking_transaction/" + bookingTransactionId);
};

const postBookingTransaction = (body) => {
	return api.post("booking_transaction/transactions", body);
};

const putBookingTransaction = (body) => {
	return api.patch("booking_transaction/" + body.id, body);
};

const postScheduleBreak = (body) => {
	return api.post("schedule_break", body);
};

const deleteScheduleBreak = (breakID) => {
	return api.delete(`schedule_break/${breakID}`);
};

const bookingTransactionService = {
	getBookingTransaction,
	postBookingTransaction,
	putBookingTransaction,
	postScheduleBreak,
	deleteScheduleBreak,
};

export default bookingTransactionService;
