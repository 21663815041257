import React from "react";
import Button from "./button";
import { Add } from "@material-ui/icons";
import { useConfig } from "context/config.context";
import "./styles.scss";
import ButtonIcon from "./button-icon";
import clsx from "clsx";

const GlobalButton = ({
	variant,
	color,
	fullWidth,
	className,
	title,
	handleClick = () => {},
	type,
	disabled = false,
	shadow = "none",
	icon,
	style,
}) => {
	const { loading } = useConfig();

	switch (type) {
		case "button":
			return (
				<Button
					variant={variant}
					color={color}
					fullWidth={fullWidth}
					className={clsx(className, disabled && "disabled")}
					handleClick={handleClick}
					title={title}
					disabled={disabled}
					style={style}
				/>
			);
		case "icon":
			return (
				<ButtonIcon
					className={clsx(className, disabled && "disabled")}
					handleClick={handleClick}
					disabled={disabled || loading}
					shadow={shadow}
					icon={icon}
					style={style}
				/>
			);
		case "button-plus":
			return (
				<button className={clsx(className, disabled && "disabled")} disabled={disabled} onClick={handleClick}>
					<Add className="icon-add-plus-size" />
				</button>
			);
		default:
			return (
				<Button
					handleClick={handleClick}
					disabled={disabled}
					title={title}
					className={clsx(className, disabled && "disabled")}
					style={style}
				/>
			);
	}
};

export default GlobalButton;
