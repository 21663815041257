import sectorService from "../../services/sectorService";
import Constants from "../../helpers/Constants";

const getSector = (businessId) => (dispatch) => {
	sectorService.getSector(businessId).then(({ data }) => {
		dispatch({ type: Constants.GET_SECTOR, payload: { sector: data } });
	});
};

const sectorActions = {
	getSector,
};

export default sectorActions;