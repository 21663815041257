import * as Yup from "yup";

export const professionalsSchema = Yup.object({
	name: Yup.string().required("Nome é obrigatório").min(3, "Nome deve ter no mínimo 3 caracteres"),
	phone: Yup.string().required("Telefone é obrigatório"),
	vacancies: Yup.number().required("Vagas é obrigatório").min(1, "Mínimo de 1 vaga").max(50, "Máximo de 50 vagas"),
	permission: Yup.string().required("Permissão é obrigatória").oneOf(["manager", "employee"], "Permissão inválida"),
});

export const initalProfessionalForm = {
	name: "",
	phone: "",
	ddi: 1,
	vacancies: 1,
	permission: "employee",
};
