import { createContext, useContext, useState } from "react";

const PaymentContext = createContext();

export function PaymentProvider({ children }) {
	const [type, setType] = useState("ActualPlan");
  const [data, setData] = useState("");
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [isCardAndPlanUpdate, setIsCardAndPlanUpdate] = useState(false);
	const [subscriptionCurrent, setSubscriptionCurrent] = useState(null);
	const [expirationDate, setExpirationDate] = useState(new Date());
	const [pixQrCode, setPixQrCode] = useState("");

	return (
		<PaymentContext.Provider
			value={{
				type,
				setType,
				data,
				setData,
				selectedPlan,
				setSelectedPlan,
				isCardAndPlanUpdate,
				setIsCardAndPlanUpdate,
				subscriptionCurrent,
				setSubscriptionCurrent,
				expirationDate,
				setExpirationDate,
				pixQrCode,
				setPixQrCode
			}}>
			{children}
		</PaymentContext.Provider>
	);
}

export function usePayment() {
	const context = useContext(PaymentContext);
	return context;
}
