import api from "./api";
import payout from "./payout";

export const getSubscriptionData = (businessCreateDate, businessId, levels, config) => {
	return api.get(`subscription_plan?enable=true&business_create_date=${businessCreateDate}&business_id=${businessId}&add_levels=${levels}`, config);
};

export const getSubscriptionCurrent = (business_id) => {
	return payout.get(`payment/subscription/prit/${business_id}`);
};

const subscriptionDataService = {
	getSubscriptionData,
	getSubscriptionCurrent,
};

export default subscriptionDataService;
