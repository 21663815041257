import React, { createRef, useEffect, useState } from "react";
import Text from "../../textComponent/text";
import styled from "styled-components";

import BoxSchedule from "./box-schedule";
import imageProfileDefault from "../../../assets/icons/profile.png";
import { deleteClientStatus, getClientBooking, putClient, updateClientStatus } from "../../../services/clientService";
import Dialog from "../../dialog";

import "./style.css";
import { useSelector } from "react-redux";
import { telephoneNumberFormat } from "../../utils";
import { BlockOutlined, CameraAltOutlined, WhatsApp, Close, ClearOutlined } from "@material-ui/icons";
import businessService from "../../../services/businessService";
import { randomInt } from "../../../utils";
import clsx from "clsx";
import { isMobile } from "utils/isMobile";
import { useConfig } from "context/config.context";

export default function ClientSide({ data, handleBlockUpdate, updateClientsList, closeClient }) {
	const refUploadLogoProfile = createRef();

	const [loading, setLoading] = useState(false);
	const [loadingBooking, setLoadingBooking] = useState(false);
	const [clientImage, setClientImage] = useState("");
	const [openBlockModal, setOpenBlockModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [listBooking, setListBooking] = useState({
		totalBookingsActive: 0,
		totalBookingsCancelled: 0,
		data: [],
	});

	const { handleFeedback } = useConfig();
	const { business } = useSelector((store) => store.business);

	const loadBooking = () => {
		setLoadingBooking(true);
		getClientBooking({
			business_id: business.id,
			client_id: data.id,
			total_cancelled: true,
			per_page: 9999,
			page: 1,
		})
			.then((response) => {
				setListBooking({
					totalBookingsActive: response.data.filter(
						(booking) => booking.status !== "inactive" && booking.status !== "cancelled"
					).length,
					totalBookingsCancelled: response.data.filter(
						(booking) => booking.status === "inactive" || booking.status === "cancelled"
					).length,
					data: response.data,
				});
				setLoadingBooking(false);
			})
			.catch((error) => {
				console.log("🚀 ~ loadBooking ~ error", error);
				handleFeedback("Atenção", "Não foi possível carregar as reservas do cliente", "error");
				setLoadingBooking(false);
			});
	};

	const handleClickLogoProfile = () => {
		refUploadLogoProfile.current.click();
	};

	const handleSendPicture = async (event) => {
		setLoading(true);
		const file = event.target.files[0];
		const formData = new FormData();
		const fileName = randomInt(1000000000).toString() + "_" + file.name;
		formData.append("image", file, fileName);
		try {
			const response = await businessService.uploadImage(formData);
			if (response.status === 200) {
				await putClient({
					...data,
					id: data.id,
					avatarUrl: "https://storage.googleapis.com/prit-images/" + fileName,
				})
					.then(() => {
						setClientImage("https://storage.googleapis.com/prit-images/" + fileName);
						updateClientsList();
					})
					.catch((error) => {
						handleFeedback("Erro", "Não foi possível atualizar a imagem do cliente", "error");
						console.log("🚀 ~ handleSendPicture ~ error", error);
					});
			}

			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const handleBlockClient = () => {
		setLoading(true);
		updateClientStatus(data.id, !data.active)
			.then(() => {
				setLoading(false);
				setOpenBlockModal(false);
				handleBlockUpdate();
				handleFeedback("Sucesso", "Cliente bloqueado com sucesso!", "success");
			})
			.catch((error) => {
				console.log("🚀 ~ handleBlockClient ~ error", error);
				handleFeedback("Erro", "Não foi possível bloquear o cliente", "error");
				setLoading();
				setOpenBlockModal(false);
			});
	};

	const handleDeleteClient = () => {
		setLoading(true);
		deleteClientStatus(data.id)
			.then(() => {
				setLoading(false);
				setOpenBlockModal(false);
				handleBlockUpdate();
				handleFeedback("Sucesso", "Cliente excluído com sucesso!", "success");
			})
			.catch((error) => {
				console.log("🚀 ~ handleDeleteClient ~ error", error);
				handleFeedback("Erro", "Não foi possível excluir o cliente", "error");
				setLoading();
				setOpenBlockModal(false);
			});
	};

	useEffect(() => {
		loadBooking();
		setClientImage(data.avatarUrl);
	}, [data]);

	return (
		<>
			<div
				className={clsx("side-client", {
					hidden: isMobile() && data === null,
				})}>
				<div className="box-client">
					<div className="client-picture">
						<StyledLogo className="client-image" background={clientImage}>
							<>
								<input type="file" name="logo" ref={refUploadLogoProfile} hidden={true} onChange={handleSendPicture} />
								<button disabled={loading} onClick={handleClickLogoProfile} className="client-image-upload">
									<CameraAltOutlined />
								</button>
							</>
						</StyledLogo>
						<a href={`https://wa.me/${data.phone.replace("+", "")}`} target="blank" className="client-btns">
							<WhatsApp />
						</a>
						<button className="client-btns" disabled={loading} onClick={() => setOpenBlockModal(true)}>
							<BlockOutlined />
						</button>
						<button className="client-btns btn-delete" disabled={loading} onClick={() => setOpenDeleteModal(true)}>
							<ClearOutlined />
						</button>
						{isMobile() && (
							<Close
								className="client-btns btn-close"
								style={{ color: "#3E3E3E", cursor: "pointer" }}
								onClick={closeClient}
							/>
						)}
					</div>
					<div className="client-details">
						<h4>Informações de contato</h4>
						<Text className="client-name" align={'left'}>{data.name}</Text>
						<Text className="client-info" style={{marginLeft: '0px'}}>{telephoneNumberFormat(data.phone)}</Text>
						<h4>Reservas</h4>
						<div className="client-schedules">
							<div className="schedules">
								<div className="schedules-number">{listBooking.totalBookingsActive}</div>
								<Text className="client-info">Agendas</Text>
							</div>
							<div className="schedules">
								<div className="schedules-number cancel">{listBooking.totalBookingsCancelled}</div>
								<Text className="client-info">Canceladas</Text>
							</div>
						</div>
					</div>
					<BoxSchedule data={listBooking.data} loading={loadingBooking} reloadBooking={() => loadBooking()} />
				</div>
			</div>
			<Dialog
				title="Realmente deseja bloquear este cliente?"
				isOpen={!!openBlockModal}
				handleFirstBtn={() => handleBlockClient()}
				handleSecondBtn={() => setOpenBlockModal(false)}
				lblBtnFirst="Sim"
				colorBtnSecond={"#FF0000"}
			/>
			<Dialog
				title="Realmente deseja excluir este cliente?"
				isOpen={!!openDeleteModal}
				handleFirstBtn={() => handleDeleteClient()}
				handleSecondBtn={() => setOpenDeleteModal(false)}
				lblBtnFirst="Sim"
				colorBtnSecond={"#FF0000"}
			/>
		</>
	);
}

const StyledLogo = styled.div`
	background-image: url("${(props) => props.background || imageProfileDefault}");
	background-repeat: no-repeat;
	background-size: ${(props) => (props.background ? "cover" : "50%")};
	background-position: center;
`;
