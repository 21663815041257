import React, { useEffect, useRef, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ArrowForwardIos } from "@material-ui/icons";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";

import {
	storeProfessional,
	updateProfessional,
	professionalService,
	getGetProfessionalService,
} from "../../../services/professionalService";
import HoursProfessionals from "./hoursProfessionals";
import businessAction from "../../../store/actions/businessAction";
import scheduleAction from "../../../store/actions/scheduleAction";
import { isMobile } from "../../../utils/isMobile";
import { trimTelephoneNumber, getDdiNumber, randomInt } from "utils";
import { Photo } from "../../common/icons/Icons";
import imageProfileDefault from "../../../assets/icons/profile.png";
import businessService from "../../../services/businessService";
import { extractBusinessServices } from "../../../store/reducers/businessServicesSelectReducer";
import { initalProfessionalForm, professionalsSchema } from "helpers/schemas/professionals-schema";
import GlobalButton from "../../globalButton";
import { useConfig } from "context/config.context";
import { Inputs } from "components";
import { telephoneNumberFormat } from "components/utils";

import useStyles from "../styles";

const FormProfessionals = ({
	handleVisibleToService = () => {},
	selectedServiceEdit = () => {},
	servicesPerfomed,
	professionalSelected,
	clear = () => {},
	getProfessionalInfo = () => {},
	activeClear,
	visibleList = () => {},
	visibleService,
}) => {
	const classes = useStyles();
	const { handleFeedback, handleLoading, loading } = useConfig();
	const dispatch = useDispatch();

	const [vacanciesTypes, setVacanciesTypes] = useState([]);
	const [hours, setHours] = useState([]);
	const { business } = useSelector((store) => store.business);
	const [isEdit, setIsEdit] = useState(false);
	const [servicesSelected, setServiceSelected] = useState([]);
	const [resetHours, setResetHours] = useState(false);
	const [loadingButton, setLoadingButton] = useState(false);
	const [imageSelected, setImageSelected] = useState(null);
	const [avatarFileName, setAvatarFileName] = useState(null);
	const { itemsServices } = useSelector((store) => store);

	const refUploadLogoProfile = useRef(null);
	const fullNameRef = useRef(null);
	const telephoneRef = useRef(null);

	const permissionTypes = [
		{ value: "manager", label: "Gerente" },
		{ value: "employee", label: "Funcionário" },
	];

	const shapeStyles = { bgcolor: "primary.main", width: 25, height: 25, borderRadius: 1, color: "white" };
	const rectangle = (
		<Box component="span" sx={shapeStyles}>
			{servicesSelected.length}
		</Box>
	);

	const {
		handleSubmit,
		setValue,
		reset,
		control,
		watch,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(professionalsSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: initalProfessionalForm,
	});

	const handleToService = () => {
		handleVisibleToService(true);
	};

	const handleClickLogoProfile = () => {
		refUploadLogoProfile.current.click();
	};

	const handleGoBack = () => {
		visibleList();
	};

	const handleSubmitImage = async (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		const fileName = randomInt(1000000000).toString() + "_" + file.name;
		formData.append("image", file, fileName);
		if (file) {
			const imageUrl = URL.createObjectURL(file);
			setImageSelected(imageUrl);
		}

		setAvatarFileName(fileName);
		try {
			await businessService.uploadImage(formData);
		} catch (error) {
			console.error(error);
		}
	};

	const onSubmit = async (data) => {
		const formatPhone = "+" + getDdiNumber(watch("ddi")) + trimTelephoneNumber(watch("phone"));

		const professionalForm = {
			businessId: business.id,
			id: null,
			avatarUrl: avatarFileName
				? "https://storage.googleapis.com/prit-images/" + avatarFileName
				: professionalSelected
				? professionalSelected.avatarUrl
				: null,
			isAvailableReservation: true,
			name: data.name,
			email: data.email,
			phone: formatPhone,
			interval: 0,
			vacancies: parseInt(data.vacancies),
			permission: data.permission,
			businessHours: [...hours],
		};
		try {
			handleLoading(true);
			if (!professionalSelected) {
				const res = await storeProfessional(professionalForm);
				await professionalService(res.data.id, { professionalServices: [...servicesSelected], grouped: false });
				handleFeedback("Sucesso", "Profissional criado com sucesso!", "success");
			} else {
				const res = await updateProfessional(professionalSelected.id, { ...professionalForm, active: true });
				await professionalService(res.data.id, { professionalServices: [...servicesSelected], grouped: false });
				handleFeedback("Sucesso", "Profissional atualizado com sucesso!", "success");
			}

			// Reset infos professional
			setImageSelected(null);
			setAvatarFileName(null);
			reset(initalProfessionalForm);
			setServiceSelected([]);
			clear(true);
			setHours([]);
			setResetHours(true);
			getProfessionalInfo(null);
			dispatch(scheduleAction.getBookingsProf());
			dispatch(businessAction.getBusiness(business?.id));
			handleGoBack();
			handleLoading(false);
		} catch (error) {
			handleLoading(false);
		}
	};

	const removeProfessional = async (professionalId) => {
		try {
			handleLoading(true);
			await updateProfessional(professionalId, { ...professionalSelected, active: false }).then(() => {
				reset(initalProfessionalForm);
				setServiceSelected([]);
				setHours([]);
				setResetHours(false);
				getProfessionalInfo(null);
				handleLoading(false);
				dispatch(scheduleAction.getBookingsProf());
				dispatch(businessAction.getBusiness(business?.id));
				handleGoBack();
				handleFeedback("Sucesso", "Profissional excluído com sucesso!", "success");
			});
		} catch (error) {
			handleLoading(false);
			console.log(error);
		}
	};

	const hoursSelected = (hours) => {
		setHours(hours);
	};

	const clearForm = () => {
		reset(initalProfessionalForm);
		const services = extractBusinessServices(itemsServices.items);
		setServiceSelected([...services]);
		setHours([]);
		setIsEdit(false);
		setResetHours(true);
	};

	const getServicesProf = async () => {
		setLoadingButton(true);
		await getGetProfessionalService(professionalSelected.id).then((res) => {
			const services = res.data.map((value) => {
				return {
					duration: value.duration,
					price: value.price,
					pricingTypeId: value.pricingTypeId,
					serviceId: value.service.id ?? null,
				};
			});
			selectedServiceEdit(res.data);
			setServiceSelected([...services]);
		});
		setLoadingButton(false);
	};

	useEffect(() => {
		const vacanciesTypesAux = [];
		for (let index = 1; index <= 50; index++) {
			vacanciesTypesAux.push({
				value: index,
				label: index,
			});
		}
		setVacanciesTypes(vacanciesTypesAux);
	}, []);

	useEffect(() => {
		setServiceSelected(servicesPerfomed);
		if (professionalSelected && !visibleService) {
			if (!servicesPerfomed || servicesPerfomed.length === 0) {
				getServicesProf();
			}
			setValue("name", professionalSelected.name);
			setValue("phone", telephoneNumberFormat(professionalSelected.phone));
			setValue("permission", professionalSelected.permission);
			setValue("vacancies", professionalSelected.vacancies);
			setIsEdit(true);
		}
		setImageSelected(null);
	}, [professionalSelected, servicesPerfomed]);

	useEffect(() => {
		if (activeClear) {
			clearForm();
		}
	}, [activeClear]);

	useEffect(() => {
		if (professionalSelected) {
			setServiceSelected(servicesPerfomed);
		} else {
			const services = extractBusinessServices(itemsServices.items);
			setServiceSelected([...services]);
		}
	}, [professionalSelected]);

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				handleSubmit(onSubmit)();
			}
		}
	};

	return (
		<>
			<div style={{ width: "100%", backgroundColor: "#EDEEF0" }} className={classes.containerForms}>
				<div className={classes.formProfessionalContainer}>
					{isMobile() && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "90%",
								marginBottom: "1rem",
							}}>
							<KeyboardBackspaceIcon sx={{ color: "#2E2E2E", paddingTop: "1rem" }} onClick={() => handleGoBack()} />
							{isEdit && (
								<div>
									<span
										style={{ color: "red", fontFamily: "Poppins", fontWeight: "400" }}
										onClick={() => removeProfessional(professionalSelected.id)}>
										Excluir
									</span>
								</div>
							)}
						</div>
					)}
					<div className={classes.mainContainer}>
						{/* header */}
						<div className={classes.formProfessionalHeader}>
							<div className={classes.formLogo}>
								{!imageSelected && !professionalSelected ? (
									<AccountCircleIcon className={classes.logo} />
								) : (
									<StyledLogo
										className={classes.logo}
										background={imageSelected ? imageSelected : professionalSelected.avatarUrl}
									/>
								)}
								<>
									<input
										type="file"
										name="logo"
										ref={refUploadLogoProfile}
										hidden={true}
										onChange={handleSubmitImage}
									/>
									<button onClick={handleClickLogoProfile} className={classes.formLogoButtonUpload}>
										<Photo />
									</button>
								</>
							</div>
							{isEdit && !isMobile() && (
								<div>
									<span style={{ color: "red" }} onClick={() => removeProfessional(professionalSelected.id)}>
										Excluir
									</span>
								</div>
							)}
						</div>
						{/* main */}
						<div
							style={{ display: "flex", flexDirection: "column", alignContent: "stretch", width: "90%", gap: "16px" }}>
							<Inputs
								control={control}
								name="name"
								type="text"
								label="Nome completo"
								className="input-bg-white"
								inputRef={fullNameRef}
								handleKeyDown={(e) => handleKeyDown(e, telephoneRef)}
							/>
							<div style={{ display: "flex", width: "100%", gap: "16px", alignItems: "center" }}>
								<Inputs control={control} name="ddi" type="ddi" className="selectFlag" />
								<Inputs
									control={control}
									name="phone"
									type="tel"
									label="Telefone"
									className="input-bg-white"
									inputRef={telephoneRef}
									handleKeyDown={(e) => handleKeyDown(e, null)}
									placeholder="Ex: (11) 9 9300-0000"
									ddi={watch("ddi")}
									style={{ flex: 1, fontFamily: "Poppins" }}
									inputProps={{
										pattern: "[0-9]*",
										inputMode: "numeric",
									}}
								/>
							</div>
							<Inputs
								control={control}
								type="select"
								name="vacancies"
								label="Número de vagas por horário"
								options={vacanciesTypes.map((option) => ({
									value: option.value,
									label: option.value === 1 ? `${option.value} vaga por horário` : `${option.value} vagas por horário`,
								}))}
								className="input-bg-white"
							/>
							<Inputs
								control={control}
								type="select"
								name="permission"
								label="Nível de permissão"
								options={permissionTypes}
								className="input-bg-white"
							/>
							<LoadingButton
								variant="outlined"
								className={classes.backgroundColorInput}
								startIcon={!loadingButton ? rectangle : ""}
								endIcon={<ArrowForwardIos />}
								onClick={handleToService}
								loading={loadingButton}>
								Serviços realizados
							</LoadingButton>
						</div>
						<HoursProfessionals
							hoursSelected={hoursSelected}
							professionalSelected={professionalSelected}
							reset={resetHours}
						/>
						<div className={classes.buttonContainer}>
							<GlobalButton
								disabled={!isValid || loading}
								title="Salvar"
								type="button"
								variant="contained"
								handleClick={handleSubmit(onSubmit)}
								className="btn-color-primary btn-default"
								fullWidth={true}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const StyledLogo = styled.div`
	background-image: url("${(props) => props.background || imageProfileDefault}");
	background-repeat: no-repeat;
	background-size: ${(props) => (props.background ? "cover" : "initial")};
	background-position: center;
`;

export default FormProfessionals;
