import styled from 'styled-components';
import { isMobile } from 'utils/isMobile';

export const ProfilePaymentContainer = styled.div`
  width: ${isMobile() ? '100%' : '80%'};
  font-family: Arial, sans-serif;
  margin: 0 auto;
`;

export const TextPrincipal = styled.h2`
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.31px;
  text-align: center;
  color: #585858;
  padding: 25px 0 12px;
`;

export const BoxCredit = styled.div`
  width: 85%;
  border: 1px solid #959393;
  border-radius: 10px;
  margin: 16px auto;
  padding: 11px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export const AuxView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const TextCreditCard = styled.p`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.31px;
  text-align: left;
  color: #585858;
  margin-bottom: 10px;
`;

export const WrapperCreditCardLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CreditCardLogo = styled.img`
  width: auto;
  height: 50px;
  object-fit: contain;
`;

export const IconWrapper = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
`;

export const BoxPIX = styled.div`
  width: 85%;
  border: 1px solid #959393;
  border-radius: 10px;
  margin: 16px auto;
  padding: 11px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export const TextPIX = styled.p`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.31px;
  text-align: left;
  color: #585858;
  margin-bottom: 10px;
`;

export const PixLogo = styled.img`
  width: auto;
  height: 50px;
  object-fit: contain;
`;
export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;

  span {
    margin-left: 10px;
    font-size: 16px;
    color: #585858;
  }
`;
