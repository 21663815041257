import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    padding: theme.spacing(4),
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    marginTop: theme.spacing(4),
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    maxWidth: "600px",
    display: "flex",
    flexDirection: "column",
    '&& input[type="text"]': {
      backgroundColor: "white !important",
    },
  },
  formGroup: {
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: theme.spacing(1),
    fontSize: "16px",
    flexGrow: 1,
    color: "#393762",
    fontWeight: "bold"
  },
  input: {
    padding: theme.spacing(1.5),
    border: "1px solid #ddd",
    borderRadius: "17px",
    fontSize: "16px",
    width: "100%",
    boxSizing: "border-box",
  },
  smallText: {
    marginTop: theme.spacing(1),
    color: "#888",
    fontSize: "12px",
  },
  SelectionService: {
		'&:hover': {
			cursor: "pointer",
			backgroundColor: "#d9d9d9",
      borderRadius: "20px",
      padding: "10px"
		}
	},
  SelectedService: {
    cursor: "pointer",
    backgroundColor: "#d9d9d9",
    borderRadius: "20px",
    padding: "10px"
	}
}));

export default useStyles;
