import scheduleService from "../../services/scheduleService.js";
import Constants from "../../helpers/Constants";

const getBookings = () => (dispatch) => {
	scheduleService.getBookings().then(({ data }) => {
		dispatch({ type: Constants.GET_BOOKINGS, payload: { bookings: data } });
	});
};

const getBookingsProf = () => (dispatch) => {
	scheduleService.getBookings().then((res) => {
		dispatch({ type: 'GET_PROFESSIONALS', payload: res.data.professionals  
		});
	});
};

const getDatedBookings = (businessId, startTime, endTime) => (dispatch) => {
	scheduleService.getDatedBookings(businessId, startTime, endTime).then(({ data }) => {
		dispatch({ type: Constants.GET_DATED_BOOKINGS, payload: { datedBookings: data } });
	});
};

const scheduleActions = {
	getBookings,
	getDatedBookings,
	getBookingsProf
};

export default scheduleActions;
