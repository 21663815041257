import axios from "axios";

const apiTwilio = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    "content-type": "application/json",
  },
});

apiTwilio.interceptors.request.use(async (config) => {
  config.headers.Authorization = process.env.REACT_APP_TOKEN_SMS_TWILIO;
  config.headers["X-User-Agent"] = "WebBusiness";
  return config;
});

const apiConfig = {
  apiTwilio,
};

export default apiConfig;
