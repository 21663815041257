import { interval } from "date-fns";
import * as Yup from "yup";

const validateTime = (start, end) => {
	if (!start || !end) return false;

	const [start_hours1, start_minutes1] = start.split(":").map(Number);
	const [end_hours2, end_minutes2] = end.split(":").map(Number);

	if (start_hours1 > end_hours2) return false;
	if (start_hours1 < end_hours2) return true;
	if (start_hours1 === end_hours2) return start_minutes1 < end_minutes2;
	return start_minutes1 > end_minutes2;
};

const hasTimeConflict = (intervals) => {
	let hasConflict = null;

	intervals.forEach((interval1) => {
		intervals
			.filter((interval2) => interval1 !== interval2)
			.forEach((interval2) => {
				if (!hasConflict) {
					if (!validateTimeConflict(interval1, interval2)) {
						hasConflict = { field1: interval1.id, field2: interval2.id };
					}
				}
			});
		if (hasConflict) return;
	});
	return hasConflict;
};

const validateTimeConflict = (interval1, intervals2) => {
	const [i1_startH] = interval1.start.split(":");
	const [i1_endH] = interval1.end.split(":");
	const [i2_startH] = intervals2.start.split(":");
	const [i2_endH] = intervals2.end.split(":");

	if (i1_endH === i2_startH) return false;
	if (i1_startH === i2_startH && i1_endH === i2_endH) return false;
	if (i1_startH <= i2_startH && i1_endH >= i2_endH) return false;
	if (i1_startH >= i2_startH && i1_endH <= i2_endH) return false;
	if (i1_startH < i2_startH && i1_endH <= i2_endH && i1_endH > i2_startH) return false;
	return true;
};

const intervalSchema = Yup.object().shape({
	id: Yup.number(),
	start: Yup.string().test("start-time", "Ajuste o horário inicial", function (start) {
		if (!start) return true;
		const end = this.parent.end;
		if (!end) return true;
		return validateTime(start, end);
	}),
	end: Yup.string().test("end-time", "Ajuste o horário final", function (end) {
		if (!end) return true;
		const start = this.parent.start;
		if (!start) return true;
		return validateTime(start, end);
	}),
});

export const openingHoursSchema = Yup.object().shape({
	timelapse: Yup.string().required("Campo obrigatório"),
	availability: Yup.string().required("Campo obrigatório"),
	earlyBooking: Yup.string().required("Campo obrigatório"),
	daysList: Yup.array().of(
		Yup.object().shape({
			id: Yup.number(),
			name: Yup.string(),
			isChecked: Yup.boolean(),
			intervals: Yup.array()
				.of(intervalSchema)
				.test("no-time-conflicts", function (intervals, context) {
					// Só valida se o dia estiver marcado
					const isChecked = context.parent.isChecked;
					if (!isChecked) return true;

					// Valida apenas se houver mais de um intervalo
					if (!intervals || intervals.length <= 1) return true;

					const conflict = hasTimeConflict(intervals);
					if (conflict) {
						// Retorna mensagem de erro customizada com os detalhes
						return this.createError({
							message: JSON.stringify({
								dayId: context.parent.id,
								message: `Conflito entre os horários: ${context.parent.name}`,
								indexes: [conflict.field1, conflict.field2],
							}),
						});
					}

					return true;
				}),
		})
	),
});

export const timeLapseList = [
	{ value: "0", label: "De acordo com a duração do serviço" },
	{ value: "15", label: "De 15 em 15 minutos" },
	{ value: "30", label: "De 30 em 30 minutos" },
	{ value: "60", label: "De 1 hora em 1 hora" },
];

export const availabilityTimeList = [
	{ value: "1|weeks", label: "1 Semana" },
	{ value: "2|weeks", label: "2 Semanas" },
	{ value: "3|weeks", label: "3 Semanas" },
	{ value: "4|weeks", label: "4 Semanas" },
	{ value: "1|months", label: "1 Mês" },
	{ value: "2|months", label: "2 Meses" },
	{ value: "3|months", label: "3 Meses" },
	{ value: "4|months", label: "4 Meses" },
	{ value: "5|months", label: "5 Meses" },
	{ value: "6|months", label: "6 Meses" },
	{ value: "7|months", label: "7 Meses" },
	{ value: "8|months", label: "8 Meses" },
	{ value: "9|months", label: "9 Meses" },
	{ value: "10|months", label: "10 Meses" },
	{ value: "11|months", label: "11 Meses" },
	{ value: "12|months", label: "12 Meses" },
];

export const defaultInitialHours = [
	{
		weekday: 1,
		openTime: "08:00",
		closeTime: "18:00",
	},
	{
		weekday: 2,
		openTime: "08:00",
		closeTime: "18:00",
	},
	{
		weekday: 3,
		openTime: "08:00",
		closeTime: "18:00",
	},
	{
		weekday: 4,
		openTime: "08:00",
		closeTime: "18:00",
	},
	{
		weekday: 5,
		openTime: "08:00",
		closeTime: "18:00",
	},
];
