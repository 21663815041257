const professionalsBusinessReducer = (state = {professionalsBusiness: []}, action) => {
    switch (action.type) {
        case 'GET_PROFESSIONALS':
            return {
                ...state,
                professionalsBusiness: action.payload
            };
        default: 
            return state;
    }
};

export default professionalsBusinessReducer;