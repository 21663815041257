import subscriptionData from "../../services/subscriptionDataService";
import Constants from "../../helpers/Constants";

const getSubscriptionData = (businessCreateDate, businessId, levels) => (dispatch) => {
  return subscriptionData
    .getSubscriptionData(businessCreateDate, businessId, levels)
    .then(({ data }) => {
      dispatch({
        type: Constants.SET_SUBSCRIPTION_DATA,
        payload: { subscription_data: data },
      });
      return data;
    })
    .catch((error) => {
      console.error("Erro na requisição getSubscriptionData:", error);
      throw error;
    });
};

const getSubscriptionCurrent = (config) => (dispatch) => {
  subscriptionData.getSubscriptionCurrent(config).then(({ data }) => {
    dispatch({
      type: Constants.SET_SUBSCRIPTION_CURRENT,
      payload: { subscription_current: data },
    });
  });
};

const subscriptionActions = {
  getSubscriptionData,
  getSubscriptionCurrent,
};

export default subscriptionActions;
