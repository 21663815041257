import React, { useState } from 'react';
import { Typography, Container } from "@material-ui/core";
import { useNavigate } from 'react-router';
import Header from '../header';
import ButtonCommom from '../common/Button';
import { Box, Modal } from '@mui/material';
import paymentService from '../../services/paymentService';
import { useSelector } from 'react-redux';


const PaymentCancel = () => {
  const businessInfor = useSelector(state => state.business);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: '12px', 
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'auto', 
    '&:focus': {
      outline: 'none',
    },
  };

  const postRecurrenceCancel = async () => {
    try {
        await paymentService.postRecurrenceCancel(businessInfor.businessInfo.id);
        navigate("/subscription/cancelar/feedback");
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        setOpen(true);
        setMessage(errorMessage);
    }
  };

    const handleClose = () => {
      setOpen(false);
      navigate("/", { state: {view: true }});
      };

  return (
    <Container maxWidth="sm" style={{ 
      background: '#fff',
      borderRadius: 3,
      border: 0,
      color: 'white',
      minWidth: '100vw',
      minHeight: '100vh',
      padding: '0 30px',
      boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
      textAlign: 'center'
    }}>
      <Header />

      <Typography variant="subtitle2" style={{ color: "#585858", fontSize: '18px', padding: "15% 0px 0% 0px", fontWeight: 'bold' }}>
        Não quer mais utilizar o Prit?
      </Typography>
      <Typography variant="subtitle2" style={{ color: "#585858", fontSize: '18px', padding: "5% 0px 0% 0px"}}>
        Se você tem certeza que quer cancelar sua assinatura, confirme abaixo.
      </Typography>

      <ButtonCommom
              text={'QUERO CANCELAR'}
              onClick={postRecurrenceCancel}
              style={{
                marginTop:'10%',
                width: window.innerWidth > 600 ? "80%" : "100%",
                fontSize: window.innerWidth > 600 ? "1em" : "2em",
                height: '45px',
              }}
      />
      
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style}}>
          <h2 id="parent-modal-title">Assinatura</h2>
          <p id="parent-modal-description">
          {message}
          </p>
        </Box>
      </Modal>

    </Container>
  );

};

export default PaymentCancel;
