import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Grid } from "@material-ui/core";
import { Modal } from "@mui/material";
import Box from "@material-ui/core/Box";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";

import { BackButtonContainer } from "../paymentMethod/styles";
import Text from "../../textComponent/text";
import CreditsCard from "assets/images/credit-card.png";
import Cards from "react-credit-cards-2";
import paymentService from "../../../services/paymentService";
import { MdChevronLeft } from "react-icons/md";
import { usePayment } from "context/payment.context";
import { useConfig } from "context/config.context";
import { isMobile } from "utils/isMobile";
import { creditCardSchema, initalCreditCardForm } from "helpers/schemas/payment-schema";
import { useForm } from "react-hook-form";
import { Button, Inputs } from "components";

import "react-credit-cards-2/dist/es/styles-compiled.css";

const CreditCardPayment = () => {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [error, setError] = useState(false);

	const { setType, data, isCardAndPlanUpdate, subscriptionCurrent } = usePayment();
	const { loading, handleLoading } = useConfig();

	const {
		control,
		watch,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(creditCardSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: initalCreditCardForm,
	});

	const numberRef = useRef(null);
	const nameRef = useRef(null);
	const expireRef = useRef(null);
	const cvcRef = useRef(null);
	const cpfRef = useRef(null);

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				fetchPaymentRecurrenceSubscription(state.initialValues);
			}
		}
	};

	const idPlan = data?.id;

	const user = useSelector((state) => state.me.me);
	const businessInfor = useSelector((state) => state.business.business);
	const cardUpdate = subscriptionCurrent.current_plan === data.title;

	const handleClose = () => {
		if (error) {
			setOpen(false);
			setError(false);
		} else {
			setType("ActualPlan");
		}
		setError(false);
		setOpen(false);
	};

	const me = user;
	const business = businessInfor;

	const state = {
		initialValues: isCardAndPlanUpdate
			? {
					recipient_id: business?.id,
					prit_plan_id: idPlan,
					card: {
						card_holder_name: watch("name") || me?.name,
						card_expiration_date: watch("expiry").replace(/\D/g, ""),
						card_number: watch("creditCard"),
						card_cvv: watch("cvc"),
					},
			  }
			: {
					recipient_id: business?.id,
					prit_plan_id: idPlan,
					card: {
						card_holder_name: watch("name") || me?.name,
						card_expiration_date: watch("expiry").replace(/\D/g, ""),
						card_number: watch("creditCard"),
						card_cvv: watch("cvc"),
					},
					client: {
						name: me?.name || "",
						email: me?.email || "",
						document_number: watch("cpf").replace(/\D/g, ""),
						phone: {
							ddd: me?.phone?.slice(3, 5) || "",
							number: me?.phone?.slice(5, 14) || "",
						},
						address: {
							street: business?.address?.street || "",
							complementary: business?.address?.unit || "",
							street_number: business?.address?.number || "",
							neighborhood: business?.address?.neighborhood || "",
							city: business?.address?.city || "",
							state: business?.address?.state || "",
							zipcode: business?.address?.postalCode || "",
							country: business?.address?.country || "",
						},
					},
					doc_type: "cpf",
			  },
	};

	const fetchPaymentRecurrenceSubscription = async (data) => {
		handleLoading(true);

		try {
			await paymentService.putPaySubscriptionCredit(data);

			setMessage("Plano alterado com sucesso!");
			setOpen(true);
		} catch (error) {
			const errorMessage = error.response?.data?.message || error.message;
			setMessage(errorMessage);
			setOpen(true);
		} finally {
			handleLoading(false);
		}
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "210px",
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		borderRadius: "12px",
		pt: 2,
		px: 4,
		pb: 3,
		overflow: "auto",
		"&:focus": {
			outline: "none",
		},
	};

	return (
		<>
			<div className="payment-content">
				<div className="btn-back">
					<BackButtonContainer onClick={() => setType("PaymentMethod")}>
						<MdChevronLeft color="#585858" size={35} />
						<span>Voltar</span>
					</BackButtonContainer>
				</div>
				<div className={clsx("payment-box-inside", isMobile() && "mobile")}>
					<Text
						variant="h6"
						align="center"
						color="textSecondary"
						gutterBottom
						style={{ padding: "1rem 0", color: "#393762" }}>
						Informe os dados do seu cartão de crédito
					</Text>
					<Text align="center" component="p">
						Nesta opção, o pagamento da mensalidade será feito de forma recorrente.
					</Text>
					<Grid container display="flex" justifyContent="center" alignItems="center">
						<Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
							<img src={CreditsCard} alt="Cartão de crédito" style={{ width: "20%", margin: "5% 0 5% 0" }} />
						</Grid>
						<Grid item xs={12}>
							<Cards cvc={watch("cvc")} expiry={watch("expiry")} name={watch("name")} number={watch("creditCard")} />
							<form
								noValidate
								autoComplete="off"
								style={{
									marginTop: "2rem",
									marginBottom: "2rem",
									display: "flex",
									flexDirection: "column",
									alignContent: "stretch",
									width: "100%",
									gap: "16px",
								}}>
								<Inputs
									control={control}
									name="creditCard"
									type="text"
									label="Número do cartão"
									placeholder="0000 0000 0000 0000"
									inputRef={numberRef}
									handleKeyDown={(e) => handleKeyDown(e, nameRef)}
									inputProps={{
										maxLength: 19,
										pattern: "[0-9]*",
										inputMode: "numeric",
									}}
								/>
								<Inputs
									control={control}
									name="name"
									type="text"
									label="Nome"
									placeholder="Nome como está no cartão"
									inputRef={nameRef}
									handleKeyDown={(e) => handleKeyDown(e, expireRef)}
								/>

								<Inputs
									control={control}
									name="expiry"
									type="text"
									label="Data de validade"
									inputRef={expireRef}
									handleKeyDown={(e) => handleKeyDown(e, cvcRef)}
									inputProps={{
										maxLength: 5,
										pattern: "[0-9]*",
										inputMode: "numeric",
									}}
								/>

								<Inputs
									control={control}
									name="cvc"
									type="text"
									label="CVC"
									inputRef={cvcRef}
									handleKeyDown={(e) => handleKeyDown(e, cpfRef)}
									inputProps={{
										maxLength: 3,
										pattern: "[0-9]*",
										inputMode: "numeric",
									}}
								/>

								<Inputs
									control={control}
									name="cpf"
									type="text"
									label="Documento"
									placeholder="000.000.000-00"
									inputRef={cpfRef}
									handleKeyDown={(e) => handleKeyDown(e, null)}
									inputProps={{
										maxLength: 14,
										pattern: "[0-9]*",
										inputMode: "numeric",
									}}
								/>

								<Button
									type="button"
									disabled={!isValid || loading}
									title={cardUpdate ? "TROCAR CARTÃO" : "ASSINAR PLANO"}
									variant="contained"
									handleClick={() => fetchPaymentRecurrenceSubscription(state.initialValues)}
									className="btn-color-primary btn-default"
									fullWidth={true}
								/>
							</form>
						</Grid>
					</Grid>
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="parent-modal-title"
						aria-describedby="parent-modal-description">
						<Box sx={{ ...style, width: "50%" }}>
							<Typography id="parent-modal-title" style={{ textAlign: "center", color: "#393762" }}>
								Assinatura OK
							</Typography>
							<p id="parent-modal-description" style={{ textAlign: "center" }}>
								{message};
							</p>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<button
									style={{
										backgroundColor: "#0074D9",
										color: "white",
										borderRadius: "10px",
										padding: "10px 20px",
										border: "none",
										cursor: "pointer",
										height: "5vh",
									}}
									onClick={() => handleClose()}>
									Ok
								</button>
							</div>
						</Box>
					</Modal>
				</div>
			</div>
		</>
	);
};
export default CreditCardPayment;
