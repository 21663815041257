import React, { useEffect, useRef } from "react";
import axios from "axios";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import businessAction from "../../../store/actions/businessAction";
import { MdChevronLeft } from "react-icons/md";
import { BackButtonContainer } from "../paymentMethod/styles";
import { usePayment } from "context/payment.context";
import { useConfig } from "context/config.context";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addressSchema, initialAddressForm } from "helpers/schemas/address-schema";
import { Button, Inputs } from "components";
import clsx from "clsx";
import { isMobile } from "utils/isMobile";

const AddressPlan = () => {
	const { setType } = usePayment();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { handleFeedback, handleLoading, loading } = useConfig();

	const {
		control,
		watch,
		setValue,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(addressSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: initialAddressForm,
	});

	const businessInfor = useSelector((state) => state.business.business);

	useEffect(() => {
		if (watch("zipcode").length === 9) {
			handleZipCode();
		}
	}, [watch("zipcode")]);

	const handleZipCode = () => {
		handleLoading(true);
		try {
			const zipcode = watch("zipcode").replace("-", "");
			axios
				.get(`https://viacep.com.br/ws/${zipcode}/json/`)
				.then((response) => {
					const data = response.data;
					setValue("address", data.logradouro);
					setValue("uf", data.uf);
					setValue("city", data.localidade);
					setValue("neighborhood", data.bairro, {
						shouldValidate: true,
						shouldDirty: true,
						shouldTouch: true,
					});
				})
				.catch((error) => {
					console.error("Erro ao buscar o CEP:", error);
				});
		} catch (error) {
			console.error("Erro ao buscar o CEP:", error);
		} finally {
			handleLoading(false);
		}
	};

	const handleSave = async () => {
		handleLoading(true);
		const address = {
			address_postal_code: watch("zipcode"),
			address_street: watch("address"),
			address_number: watch("number"),
			address_unit: watch("complement"),
			address_neighborhood: watch("neighborhood"),
			address_state: watch("uf"),
			address_city: watch("city"),
			address_country: "Brasil",
		};

		if (businessInfor && businessInfor.id) {
			try {
				handleFeedback("Sucesso", "Endereço atualizado com sucesso.", "success");
				dispatch(businessAction.updateBusinessAddress(businessInfor.id, address));
				setType("CreditCardPayment");
			} catch (error) {
				let errorMessage = "Ocorreu um erro desconhecido. Por favor, tente novamente.";

				if (error.response) {
					if (error.response.data && error.response.data.message) {
						errorMessage = error.response.data.message;
					} else {
						errorMessage = "Erro ao acessar a página. Verifique suas permissões.";
					}
				} else if (error.request) {
					errorMessage = "Não foi possível conectar ao servidor. Por favor, verifique sua conexão de internet.";
				} else {
					errorMessage = error.message;
				}
				handleFeedback("Erro", `Erro ao atualizar o endereço: ${errorMessage}`, "error");
			} finally {
				handleLoading(false);
			}
		}
	};

	const cepRef = useRef(null);
	const addressRef = useRef(null);
	const numberRef = useRef(null);
	const complementRef = useRef(null);
	const referencePointRef = useRef(null);
	const ufRef = useRef(null);
	const cityRef = useRef(null);
	const neighborhoodRef = useRef(null);

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				if (isValid) handleSave();
			}
		}
	};

	return (
		<>
			<div className="payment-content">
				<div className="btn-back">
					<BackButtonContainer onClick={() => setType("PaymentMethod")}>
						<MdChevronLeft color="#585858" size={35} />
						<span>Voltar</span>
					</BackButtonContainer>
				</div>

				<div className={clsx("payment-box-inside bg-gray", isMobile() && "mobile")}>
					<div className={classes.formContainer}>
						<label htmlFor="cep" className={classes.label}>
							Endereço
						</label>
						<br />
						<form
							className={classes.form}
							style={{ display: "flex", flexDirection: "column", alignContent: "stretch", width: "100%", gap: "16px" }}>
							<Inputs
								control={control}
								name="zipcode"
								type="text"
								label="CEP"
								placeholder="Digite o CEP: 00000-000"
								inputRef={cepRef}
								handleKeyDown={(e) => handleKeyDown(e, addressRef)}
								inputProps={{
									maxLength: 9,
								}}
							/>
							<Inputs
								control={control}
								name="address"
								type="text"
								label="Logradouro"
								inputRef={addressRef}
								handleKeyDown={(e) => handleKeyDown(e, numberRef)}
							/>
							<Inputs
								control={control}
								name="number"
								type="text"
								label="Número"
								inputRef={numberRef}
								handleKeyDown={(e) => handleKeyDown(e, complementRef)}
							/>
							<Inputs
								control={control}
								name="complement"
								type="text"
								label="Complemento"
								helper='Caso não tenha, escreva "sem complemento"'
								inputRef={complementRef}
								handleKeyDown={(e) => handleKeyDown(e, referencePointRef)}
							/>
							<Inputs
								control={control}
								name="referencePoint"
								type="text"
								label="Ponto de Referência"
								placeholder="Digite o ponto de referência"
								inputRef={referencePointRef}
								handleKeyDown={(e) => handleKeyDown(e, ufRef)}
							/>
							<Inputs
								control={control}
								name="uf"
								type="text"
								label="UF"
								inputRef={ufRef}
								handleKeyDown={(e) => handleKeyDown(e, cityRef)}
							/>
							<Inputs
								control={control}
								name="city"
								type="text"
								label="Cidade"
								inputRef={cityRef}
								handleKeyDown={(e) => handleKeyDown(e, neighborhoodRef)}
							/>
							<Inputs
								control={control}
								name="neighborhood"
								type="text"
								label="Bairro"
								inputRef={neighborhoodRef}
								handleKeyDown={(e) => handleKeyDown(e, null)}
							/>
							<Button
								type="button"
								variant="contained"
								title="Salvar"
								fullWidth={true}
								className="btn-color-primary btn-default"
								disabled={!isValid || loading}
								handleClick={() => {
									handleSave();
								}}
							/>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddressPlan;
