import React from "react";
import  "./style.scss";
import Chip from "@mui/material/Chip";

const Status = (statusType) => {
	const statusStyles = (bookingPaymentStatus) => {
		switch (bookingPaymentStatus) {
			case "pending":
				return { className: "pendingStatus", label: "Pendente" };
			case "cancelled":
				return { className: "cancelledStatus", label: "Cancelado" };
			case "completed":
				return { className: "completedStatus", label: "Pago" };
			case "partial":
				return { className: "partialStatus", label: "Parcial" };
			case "refuse":
				return { className: "refuseStatus", label: "Recusado" };
			case "active":
				return { className: "activeStatus", label: "Agendado" };
			case "inactive":
				return { className: "inactiveStatus", label: "Inativo" };
			case "pending_accept":
				return { className: "pendingAcceptStatus", label: "Pendente de Aceitação" };
			case "requested":
				return { className: "requestedStatus", label: "Solicitado" };
			case "accept":
				return { className: "acceptStatus", label: "Confirmado" };
			case "refund":
				return { className: "refundStatus", label: "Estornado" };
			default:
				return { className: "defaultStatus", label: "Indefinido" };
		}
	};

	const styles = statusStyles(statusType.statusType);

	return <Chip label={styles.label} className={styles.className} />;
};

export default Status;
