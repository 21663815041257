import React from "react";
import Text from "../../textComponent/text";

const MenuItem = ({ title, icon, action, isActive }) => {
  return (
    <div style={{display: "flex", alignItems: "center", padding: "0.4rem", width: "250px"}} onClick={action}>
      <Text style={{marginTop: "0.2rem"}}>
        {icon}
      </Text>
      <Text
      style={{
        fontSize: "16px",
        color: "darkgray",
        textAlign: "left",
      }}
      >
       {title}
    </Text>
    </div>
  );
};

export default MenuItem;