import _ from 'lodash';
// import i18next from 'i18next'; @TODO

import cartesianProduct from '../utils/cartesianProduct';

export const createFeatureGroups = (features /*, onboard*/) => {
  const nameLanguage = 'name';
// @TODO
//   if (onboard && i18next.language === 'en-US') {
//     nameLanguage = 'nameEng';
//   }
  return _.chain(features)
    .filter(x => x.featureGroups && x.featureGroups.length > 0)
    .map((feature) => {
      const group = _.first(feature.featureGroups);
      group.featureGroupKeys = group.featureGroupKeys.map(elem => (
        {
          featureGroupValues: elem.featureGroupValues.map(eleme => (
            {
              id: eleme.id,
              name: eleme[nameLanguage],
              nameEng: eleme.nameEng,
            }
          )),
        }
      ));
      const groupKeys = _.orderBy(group.featureGroupKeys, 'order');
      const groupValues = _.map(groupKeys, 'featureGroupValues');

      let items = cartesianProduct(groupValues);

      items = _.map(items, tuple => ({
        id: _.map(tuple, 'id').join('|'),
        name: _.map(tuple, nameLanguage).join(' + '),
        nameEng: _.map(tuple, nameLanguage).join(' + '),
        featureGroupValues: tuple,
        featureItems: _.filter(feature.featureItems, x => (
          _.isEqual(
            _.map(x.featureItemGroups, 'featureGroupValueId').sort(),
            _.map(tuple, 'id').sort(),
          )
        )),
      }));

      return {
        feature,
        group,
        items,
      };
    })
    .value();
};

export const groupFeatureItems = (feature/*, onboard*/) => {
  const nameLanguage = 'name';
//   if (onboard && i18next.language === 'en-US') {
//     nameLanguage = 'nameEng';
//   }
  const isGrouped = feature && feature.featureGroups && feature.featureGroups.length > 0;
  let items = feature && feature.featureItems;

  if (isGrouped) {
    const group = _.first(feature.featureGroups);
    group.featureGroupKeys = group.featureGroupKeys.map(elem => (
      {
        featureGroupValues: elem.featureGroupValues.map(eleme => (
          {
            id: eleme.id,
            name: eleme[nameLanguage],
            nameEng: eleme.nameEng,
          }
        )),
      }
    ));
    const groupKeys = _.orderBy(group.featureGroupKeys, 'order');
    const groupValues = _.map(groupKeys, 'featureGroupValues');

    items = cartesianProduct(groupValues);
    items = _.map(items, tuple => ({
      id: _.map(tuple, 'id').join('|'),
      name: _.map(tuple, nameLanguage).join(' + '),
      nameEng: _.map(tuple, nameLanguage).join(' + '),
      featureGroupValues: tuple,
      featureId: feature.id,
      isGroup: true,
    }));
  }

  return items;
};

export const createFeatureTree = (item, features, allFeaturesMap, onboard) => {
  const [feature, ...rest] = features;

  const items = groupFeatureItems(feature, onboard);

  if (!items) {
    return _.cloneDeep(item);
  }

  const childItems = _.map(items, (current) => {
    let nextFeatures = rest;

    if (current.availableFeatures && current.availableFeatures.length > 0) {
      nextFeatures = _.chain(current.availableFeatures)
        .orderBy('level')
        .map(({ featureId }) => allFeaturesMap[featureId])
        .value();
    }

    return createFeatureTree(current, nextFeatures, allFeaturesMap, onboard);
  });
  const nameLanguage = 'name';
//   if (onboard && i18next.language === 'en-US') {
//     nameLanguage = 'nameEng';
//   }
  return {
    ...(_.cloneDeep(item)),
    items: _.sortBy(childItems, nameLanguage),
  };
};

export const visitTree = (node, visitor, path = []) => {
  const items = node.items && _.map(node.items, (child) => {
    const childPath = [...path, child.id];
    const visitedChild = visitTree(child, visitor, childPath);
    return visitor(visitedChild, childPath, node);
  });

  return {
    ...node,
    items,
  };
};

export const findNode = (node, [id, ...path]) => {
  if (!id) {
    return node;
  }

  const parentNode = _.find(node.items, { id });

  if (!parentNode) {
    return null;
  }

  return findNode(parentNode, path);
};

export const isChildOf = (path, parentPath) => {
  if (!path || !parentPath) {
    return false;
  }

  if (path.length <= parentPath) {
    return false;
  }

  return _.isEqual(path.slice(0, parentPath.length), parentPath);
};

export const updateServiceSelectionVisitor = (path, selected) => (node, nodePath) => {
  // Forces all child nodes to have the same selected state
  if (_.isEqual(path, nodePath) || isChildOf(nodePath, path)) {
    return {
      ...node,
      selected,
    };
  }
  // Update parent nodes to have consistent selected state
  if (node.items) {
    const isSelected = _.some(node.items, { selected: true });
    const showing = _.some(node.items, { showing: true });

    return {
      ...node,
      selected: isSelected,
      showing,
    };
  }
  return node;
};