import React from 'react';
import './text.scss';

const Text = ({variant, children, align, className, ...props}) => {
  const variantClass = variant ? `text--${variant}` : `text-body`;
  const alignClass = align ? `text--align-${align}` : '';

  return (
    <p className={`text ${variantClass} ${alignClass} ${className || ''}`} {...props}>
      {children}
    </p>
  );
};

Text.defaultProps = {
  variant: 'body',
  className: '',
};

export default Text;