import Constants from "../../helpers/Constants";
import clientService from "../../services/clientService";

const getClients = (businessId,myProfessionalId) => (dispatch) => {
	clientService.getClients(businessId,myProfessionalId).then(({ data }) => {
		dispatch({ type: Constants.GET_CLIENTS, payload: { clients: data } });
	});
};

const clientAction = {
	getClients
};

export default clientAction;