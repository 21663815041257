import React from "react";
import ProfilePritPlan from "../../components/profilePritPlan";
import Address from "../../components/profilePritPlan/adressPlan";
import PaymentMethod from "../../components/profilePritPlan/paymentMethod";
import CreditCard from "../../components/profilePritPlan/creditCardPayment";
import PixQrCode from "../../components/profilePritPlan/pixPayment/pixQrCode";
import Pix from "../../components/profilePritPlan/pixPayment/pix";
import ActualPlan from "../../components/profilePritPlan/actualPlan";
import { usePayment } from "context/payment.context";
import { Header } from "components";
import { Divider } from "@material-ui/core";

const SchedulePlanPages = () => {
	const { type } = usePayment();
	const selectPage = () => {
		switch (type) {
			case "ActualPlan":
				return <ActualPlan />;
			case "PritPlan":
				return <ProfilePritPlan />;
			case "PaymentMethod":
				return <PaymentMethod />;
			case "AddressPlan":
				return <Address />;
			case "CreditCardPayment":
				return <CreditCard />;
			case "Pix":
				return <Pix />;
			case "PixQrCode":
				return <PixQrCode />;
			default:
				return <ActualPlan />;
		}
	};

	return (
		<>
			<Header title="Assinatura" />
			<Divider />
			{selectPage()}
		</>
	);
};

export default SchedulePlanPages;
