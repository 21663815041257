import React from "react";
import { FormControlLabel, FormLabel, Switch } from "@material-ui/core";

const InputSwitch = ({ defaultChecked, checked, handleChange, name, className, disabled = false, label }) => {
	return (
		<FormControlLabel
			control={
				<Switch
					defaultChecked={defaultChecked}
					checked={checked}
					onChange={handleChange}
					name={name}
					disabled={disabled}
					className={["input-switch", className].join(" ")}
					title={label}
				/>
			}
			label={label && <FormLabel className="input-label">{label}</FormLabel>}
		/>
	);
};

export default InputSwitch;
