import React, { useState } from 'react';
import { Typography, Container } from "@material-ui/core";
import { useNavigate } from 'react-router';
import Header from '../header';
import ButtonCommom from '../common/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const PaymentCancelFeedback = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const clickOK = () => {
    navigate("/subscription/plan", { state: {cancel: true} });
  };

  return (
    <Container maxWidth="sm" style={{ 
      background: '#fff',
      borderRadius: 3,
      border: 0,
      color: 'white',
      minWidth: '100vw',
      minHeight: '100vh',
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      textAlign: 'center'
    }}>
      <Header />

      <Typography variant="subtitle2" style={{ color: "#585858", fontSize: '18px', padding: "15% 0px 0% 0px", fontWeight: 'bold' }}>
        Nós cancelamos a sua assinatura.
      </Typography>
      <Typography variant="subtitle2" style={{ color: "#585858", fontSize: '18px', padding: "5% 0px 0% 0px"}}>
        Nós estamos sempre preocupados em melhorar o nosso aplicativo, seu feedback é importante pra gente.       
      </Typography>

      <FormControl style={{ color: "#585858", fontSize: '18px', padding: "5% 0px 0% 0px"}}>
      <Typography style={{textAlign: 'justify', padding:'5% 0'}} >Por que você cancelou sua assinatura?</Typography>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel style={{ color: "#585858" }} value="0" control={<Radio />} label="Meus clientes não estão agendando online comigo pelo app" />
        <FormControlLabel style={{ color: "#585858" }} value="1" control={<Radio />} label="Tenho muito pouco movimento" />
        <FormControlLabel style={{ color: "#585858" }} value="2" control={<Radio />} label="Estou fechando o meu estabelecimento" />
        <FormControlLabel style={{ color: "#585858" }} value="3" control={<Radio />} label="Fui para outro aplicativo " />
        <FormControlLabel style={{ color: "#585858" }} value="4" control={<Radio />} label="Achei o app muito difícil de mexer" />
        <FormControlLabel style={{ color: "#585858" }} value="5" control={<Radio />} label="Outro" />
      </RadioGroup>
    </FormControl>

      <ButtonCommom
              text={'CONCLUÍDO'}
              onClick={clickOK}
              disable= {value === ''}
              style={{
                marginTop:'10%',
                width: window.innerWidth > 600 ? "80%" : "100%",
                fontSize: window.innerWidth > 600 ? "1em" : "2em",
                height: '45px',
              }}
      />
    </Container>
  );

};

export default PaymentCancelFeedback;
