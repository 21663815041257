import React, { useEffect, useState } from "react";
import Text from "../../textComponent/text.jsx";
import styled from "styled-components";
import useStyles from "../styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useSelector } from "react-redux";
import imageProfileDefault from "../../../assets/icons/profile.png";
import GlobalButton from "../../globalButton";

const ListProfessionals = ({ getProfessionalInfo = () => {}, clearForm = () => {}, visibleList = () => {} }) => {
	const classes = useStyles();

	const { business } = useSelector((store) => store.business);

	const [professionals, setProfessionals] = useState([]);

	useEffect(() => {
		if (business?.professionals) {
			const professionals = business?.professionals.map((value) => ({
				...value,
				select: false,
			}));
			setProfessionals(professionals);
		}
	}, [business?.professionals, business]);

	const getProfessional = (professional) => {
		getProfessionalInfo(professional);
	};

	const clear = () => {
		clearForm(true);
		visibleListProf(false);
	};

	const visibleListProf = (data) => {
		visibleList(data);
	};

	return (
		<div className={classes.listProfessionalContainer}>
			<ul className={classes.infoProfessionalContainer}>
				{professionals.map((professional, index) => (
					<React.Fragment key={professional.id}>
						<li className={classes.listProfessionalsDisplay} onClick={() => getProfessional(professional)}>
							<div className={classes.listProfessionals}>
								{professional.avatarUrl ? (
									<StyledLogo
										className={classes.logoListProfessional}
										background={
											professional.avatarUrl
										}>
										
									</StyledLogo>
								) : (
									<AccountCircleIcon
										style={{
											color: "darkgray",
											width: "70px",
											height: "70px",
											cursor: "pointer",
											borderRadius: "100%",
											alignSelf: "center",
											margin: "10px",
										}}
									/>
								)}
								<Text>
									{professional.name || `Professional ${index + 1}`}
								</Text>
							</div>
						</li>
					</React.Fragment>
				))}
			</ul>
			<GlobalButton type="button-plus" className="btn-add-plus btn-add-plus-color-bg-default btn-add-plus-color-default" handleClick={() => clear()}/>
		</div>
	);
};

const StyledLogo = styled.div`
	background-image: url("${(props) => props.background || imageProfileDefault}");
	background-repeat: no-repeat;
	background-size: ${(props) => (props.background ? "cover" : "initial")};
	background-position: center;
`;

export default ListProfessionals;
