import * as Yup from "yup";
import { validateEmail } from "utils/validators";

const PHONE_REGEX = {
	"1": /^(?:\+?\d{1,3}[\s-]?)?\(?\d{2}\)?[\s-]?\d{5}[\s-]?\d{4}$/,
	"2": /^\+?\d{1,3}[\s-]?\d{3}[\s-]?\d{3}[\s-]?\d{3}$/,
	"3": /^\+?\d{1,3}[\s-]?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/,
	"4": /^\+?\d{1,3}[\s-]?\d{5}[\s-]?\d{6}$/,
	"5": /^\+?\d{1,3}[\s-]?\d{3}[\s-]?\d{3}[\s-]?\d{3}$/,
	"6": /^\+?\d{1,3}[\s-]?\d{2}[\s-]?\d{2}[\s-]?\d{2}[\s-]?\d{2}[\s-]?\d{2}$/,
};

const getPhoneRegex = (ddi) => {
  return PHONE_REGEX[ddi] || PHONE_REGEX["1"];
};

export const initialLoginForm = {
  name: "",
  email: "",
  ddi: 1,
  phone: "",
  businessName: "",
};

export const loginSchema = Yup.object({
  ddi: Yup.string().required("Selecione o DDI."),
  phone: Yup.string()
    .required("Telefone é obrigatório")
    .test("is-valid-phone", "Telefone inválido.", function (value) {
      const { ddi } = this.parent;
      const regex = getPhoneRegex(ddi);
      return regex.test(value);
    }),
});

export const newBusinessSchema = Yup.object({
  name: Yup.string().required("Nome é obrigatório"),
  email: Yup.string()
    .required("E-mail é obrigatório")
    .test("email", "E-mail inválido", (value) => validateEmail(value)),
  ddi: Yup.string().required("Selecione o DDI."),
  phone: Yup.string()
    .required("Telefone é obrigatório")
    .test("is-valid-phone", "Telefone inválido.", function (value) {
      const { ddi } = this.parent;
      const regex = getPhoneRegex(ddi);

      return regex.test(value);
    }),
  businessName: Yup.string().required("Nome do negócio é obrigatório"),
});
