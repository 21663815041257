import * as Yup from "yup";

export const searchSchema = Yup.object({
	search: Yup.string().min(2, "Mínimo 2 caracteres").required("Campo obrigatório"),
});

export const initialSearchForm = {
	search: "",
};

export const newClientSchema = Yup.object({
	name: Yup.string().required("Campo obrigatório"),
	phone: Yup.string().required("Campo obrigatório").min(15, "Telefone inválido"),
});

export const initialNewClientForm = {
	name: "",
	phone: "",
	ddi: 1,
	email: "",
};
