import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Text from "../textComponent/text";
import { useMixpanel } from "react-mixpanel-browser";
import sectorService from "../../services/sectorService";
import CoreContainer from "../common/new/container";
import HelpButtonComponent from "../../components/chipComponent/index.jsx";
import CoreAppBar from "../common/new/appBar";
import { useConfig } from "context/config.context";

const SelectSector = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { setLoading } = useConfig();
	const mixpanel = useMixpanel();

	const { fullName, email, businessName, phone } = location?.state || {};
	const [sectors, setSectors] = useState([]);

	useEffect(() => {
		setLoading(true);

		const request = setTimeout(() => {
			sectorService.getSectors().then((res) => {
				setSectors(res.data.sort((a, b) => a.name.localeCompare(b.name)));
				setLoading(false);
			});
		}, 1000);

		return () => clearInterval(request);
	}, []);

	return (
		<CoreContainer style={{ gap: "16px" }}>
			<div style={{ display: "flex", alignContent: "center", width: "100%", marginBottom: "1rem" }}>
				<CoreAppBar progress={75} />
				<HelpButtonComponent help={true} />
			</div>

			<Text style={{ color: "#3E3E3E" }} variant={"mid"}>
				Qual sua área de atuação?
			</Text>

			<Text style={{ color: "#9C9C9C", marginBottom: "1rem" }}>
				Informe a sua área de atuação para que possamos prosseguir o cadastro com as opções mais adequadas a sua
				realidade.
			</Text>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(2, 1fr)",
					gap: "16px",
					width: "100%",
					paddingRight: "16px",
				}}>
				{sectors.map((sector) => (
					<div
						style={{
							backgroundColor: "#F1F1F1",
							padding: "8px 16px",
							borderRadius: "8px",
							cursor: "pointer",
							height: window.innerWidth < window.innerHeight ? "60px" : "30px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						onClick={() => {
							navigate("/selecionar-categorias", {
								state: { phone, fullName, email, businessName, sectorId: sector.id },
							});
							mixpanel.track("onb. WEB. - Avançar Setor");
						}}>
						<Text style={{ color: "#3E3E3E" }} align={"center"}>
							{sector.name}
						</Text>
					</div>
				))}
			</div>
		</CoreContainer>
	);
};

export default SelectSector;
