import api from "./api";
import _ from 'lodash';

export const getProfessionalServices = (professionalId, categoryId) => {
	return api.get("professional_service/last_update?professional_id[]=" + professionalId + "&category_id=" + categoryId);
};

export const getServicesByCategoryId = (businessId, categoryId, language) => {
	return api.get(`business_service/business/${businessId}/new`, {
		params: {
			category_id: categoryId,
			language: language || "portuguese",
		},
	});
};

export const updateBusinessServices = (businessId, businessServices) => {
	return api.put(`business/${businessId}/business_service`, {
		params: {
			businessServices,
		},
	});
};

export const checkNewsServices = (
	categoryIds,
	lastUpdateDate,
	config = {},
	language,
	grouped = null,
) => {
	if (lastUpdateDate) {
		if (_.isNil(grouped)) {
			return api.get(
				'service/validate',
				{
					params: {
						category_id: categoryIds,
						date: lastUpdateDate,
						language,
					},
				},
				config,
			);
		}
		return api.get(
			'service/validate',
			{
				params: {
					category_id: categoryIds,
					date: lastUpdateDate,
					grouped,
					language,
				},
			},
			config,
		);
	}
	if (_.isNil(grouped)) {
		return api.get(
			'service/validate',
			{
				params: {
					category_id: categoryIds,
					language,
				},
			},
			config,
		);
	}
	return api.get(
		'service/validate',
		{
			params: {
				category_id: categoryIds,
				grouped,
				language,
			},
		},
		config,
	);
};

export const businessServicesLastUpdate = (
	businessId,
	lastUpdate,
	config = {},
	grouped = null,
) => {
	if (lastUpdate !== null) {
		if (_.isNil(grouped)) {
			return api.get(
				`business_service/business/${businessId}/last_update?date=${lastUpdate}`,
				config,
			);
		}
		return api.get(
			`business_service/business/${businessId}/last_update?date=${lastUpdate}`,
			{
				params: {
					grouped,
				},
			},
			config,
		);
	}
	if (_.isNil(grouped)) {
		return api.get(
			`business_service/business/${businessId}/last_update`,
			config,
		);
	}
	return api.get(
		`business_service/business/${businessId}/last_update`,
		{
			params: {
				grouped,
			},
		},
		config,
	);
};

export const sectorLastUpdate = (businessId, lastUpdate, language, config = {}) => {
	if (lastUpdate) {
		return api.get(
			`sector/business/${businessId}?date=${lastUpdate}&language=${language}`,
			config,
		);
	}
	return api.get(
		`sector/business/${businessId}?language=${language}`,
		config,
	);
};

export const bulkUpdate = (businessId, data, config) => {
	return api.put(
		`business/${businessId}/business_service`,
		data,
		config,
		);
};

export const bulkUpdateAsync = (businessId, data, config) => {
	return api.put(
		`business/${businessId}/business_services_async`,
		data,
		config,
		);
};

const apiActions = {
  getProfessionalServices,
  getServicesByCategoryId,
  updateBusinessServices,
  checkNewsServices,
  businessServicesLastUpdate,
  sectorLastUpdate,
  bulkUpdate,
  bulkUpdateAsync,
};

export default apiActions;