import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import { Close } from "@material-ui/icons";
import Text from "../textComponent/text.jsx";
import { businessProfessionals } from "../../services/businessService";
import { useConfig } from "context/config.context";
import "./styles.scss";
import businessAction from "../../store/actions/businessAction";
import { useDispatch, useSelector } from "react-redux";

const ChangeBusiness = ({ isOpen, handleClose }) => {
	const [listBusiness, setListBusiness] = useState([]);
	const [loading, setLoading] = useState(false);
	const { handleLoading } = useConfig();
	const { business: selectedBusiness } = useSelector((store) => store.business);

	const dispatch = useDispatch();

	const professinalBusiness = async () => {
		handleLoading(true);
		setLoading(true);
		await businessProfessionals().then((res) => {
			const business = [...res.data];
			setListBusiness(business);
			handleLoading(false);
			setLoading(false);
		});
	};

	const selectBusiness = (businessId) => {
		handleLoading(true);
		dispatch(businessAction.getBusiness(businessId, true));
	};

	const ContainerListBusiness = styled.div`
	background-color: ${({isSelected}) => (isSelected ? '#3689ea' : 'white')}
	`;

	const list = () => {
		return listBusiness.map((business) => (
			<ContainerListBusiness 
			key={business.id} 
			onClick={() => selectBusiness(business.id)} 
			className="container-list-business"
			isSelected={business.id === selectedBusiness?.id}
			>
				<StyledLogo className="logo" background={business.thumbnailUrl} />
				<Text style={{ color: `${business.id === selectedBusiness?.id && 'white'}`}}>{business.name}</Text>
			</ContainerListBusiness>
		));
	};

	useEffect(async () => {
		await professinalBusiness();
	}, []);

	return (
		<Dialog
			onClose={handleClose}
			open={isOpen}
			PaperProps={{
				style: { borderRadius: 12, maxWidth: 400, padding: 10, gap: 10 },
			}}>
				<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
					<Text style={{ color: "#616161" }}>Selecione o estabelecimento que deseja acessar</Text>
					<Close
						style={{ color: "#3E3E3E", cursor: "pointer", marginLeft: "1px" }}
						onClick={() => handleClose()}></Close>
				</div>
				{ !loading ? list() : <Text style={{ color: "#616161" }}>Carregando estabelecimento...</Text>}
		</Dialog>
	);
};

const StyledLogo = styled.div`
	background-image: url("${(props) => props.background}");
	background-repeat: no-repeat;
	background-size: ${(props) => (props.background ? "cover" : "initial")};
	background-position: center;
`;


export default ChangeBusiness;
