import React, { useEffect, useRef, useState } from "react";
import { Typography, Container, Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import CreditsCard from "assets/images/credit-card.png";
import { useLocation, useNavigate } from "react-router";
import { Modal } from "@mui/material";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";

import Header from "../header";
import { useDispatch, useSelector } from "react-redux";
import paymentService from "../../services/paymentService";
import { useConfig } from "context/config.context";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { creditCardSchema, initalCreditCardForm } from "helpers/schemas/payment-schema";
import Inputs from "../inputs";
import meAction from "store/actions/meAction";
import GlobalButton from "../globalButton";
import { isMobile } from "utils/isMobile";
import { useLogin } from "context/login.context";

const PaymentCredit = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const idPlan = location?.state?.id;

	const dispatch = useDispatch();

	const { setTypeAccess, handleLoading } = useConfig();
	const { clearBusinessState } = useLogin();

	const user = useSelector((state) => state.me.me);
	const businessInfor = useSelector((state) => state.business);

	const getBusinessId = () => {
		if (businessInfor) {
			if (businessInfor.business && businessInfor.business.id) {
				return businessInfor.business.id;
			}
			return businessInfor.businessInfo.id;
		}
		return null;
	};

	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [error, setError] = useState(false);
	const [state, setState] = useState();

	const {
		control,
		watch,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(creditCardSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: initalCreditCardForm,
	});

	const numberRef = useRef(null);
	const nameRef = useRef(null);
	const expireRef = useRef(null);
	const cvcRef = useRef(null);
	const cpfRef = useRef(null);

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				fetchPaymentRecurrenceSubscription(state.initialValues);
			}
		}
	};

	const handleClose = () => {
		if (error) {
			setOpen(false);
			setError(false);
		} else {
			clearBusinessState();
			setTypeAccess("schedule");
			navigate("/schedule");
		}
		setError(false);
		setOpen(false);
	};

	const isCardAndPlanUpdate = location?.state.isCardAndPlanUpdate;
	const cardUpdate = location?.state.cardUpdate;

	const me = user;
	const business = businessInfor?.business;

	const fetchPaymentRecurrenceSubscription = (data) => {
		handleLoading(true);
		if (isCardAndPlanUpdate || cardUpdate) {
			const dataCard = cardUpdate
				? {
						recipient_id: getBusinessId(),
						card: {
							card_holder_name: watch("name"),
							card_expiration_date: watch("expiry").replace(/\D/g, ""),
							card_number: watch("creditCard"),
							card_cvv: watch("cvc"),
						},
				  }
				: {
						...state,
						card: {
							card_holder_name: watch("name"),
							card_expiration_date: watch("expiry").replace(/\D/g, ""),
							card_number: watch("creditCard"),
							card_cvv: watch("cvc"),
						},
						client: {
							...state.client,
							document_number: watch("cpf").replace(/\D/g, ""),
						},
				  };

			handleLoading(true);
			paymentService
				.putPaySubscriptionCredit(dataCard)
				.then((response) => {
					setOpen(true);
					if (cardUpdate) {
						setMessage("Alteração feita");
					} else {
						setMessage(response.data.message);
					}
				})
				.catch((error) => {
					let errorMessage = error.message;
					if (error.response && error.response.data) {
						errorMessage = error.response.data.message || error.response.data;
						setOpen(true);
						setError(true);
						setMessage(errorMessage);
					}
				})
				.finally(() => {
					handleLoading(false);
				});
		} else {
			const dataPayment = {
				...data,
				card: {
					card_holder_name: watch("name"),
					card_expiration_date: watch("expiry").replace(/\D/g, ""),
					card_number: watch("creditCard"),
					card_cvv: watch("cvc"),
				},
				client: {
					...data.client,
					document_number: watch("cpf").replace(/\D/g, ""),
				},
				recipient_id: getBusinessId(),
			};
			console.log("🚀 ~ POST", dataPayment);
			paymentService
				.postPaySubscriptionCredit(dataPayment)
				.then((response) => {
					setOpen(true);
					setMessage(response.data.message);
				})
				.catch((error) => {
					let errorMessage = error.message;

					if (error.response && error.response.data) {
						errorMessage = error.response.data.message || error.response.data;
						if (
							errorMessage === "The request model has some validation errors." ||
							errorMessage === "Object reference not set to an instance of an object."
						) {
							errorMessage =
								"A solicitação possui alguns erros de validação. Tente novamente, caso o erro persista, entre em contato com nosso suporte.";
						}
						setOpen(true);
						setError(true);
						setMessage(errorMessage);
					}
				})
				.finally(() => {
					handleLoading(false);
				});
		}
	};

	useEffect(() => {
		if (!me) dispatch(meAction.getMe());
		if (me) {
			setState({
				initialValues: isCardAndPlanUpdate
					? {
							recipient_id: getBusinessId(),
							card: {
								card_holder_name: watch("name"),
								card_expiration_date: watch("expiry").replace(/\D/g, ""),
								card_number: watch("creditCard"),
								card_cvv: watch("cvc"),
							},
							prit_plan_id: idPlan,
					  }
					: {
							recipient_id: getBusinessId(),
							card: {
								card_holder_name: watch("name"),
								card_expiration_date: watch("expiry").replace(/\D/g, ""),
								card_number: watch("creditCard"),
								card_cvv: watch("cvc"),
							},
							client: {
								name: me ? me.name : "",
								email: me ? me.email : "",
								document_number: watch("cpf").replace(/\D/g, ""),
								phone: { ddd: me.phone.slice(3, 5), number: me.phone.slice(5, 14) },
								address: {
									street: business?.address.street ? business?.address.street : "Jardim Botânico",
									complementary: business?.address.unit,
									street_number: business?.address.number,
									neighborhood: business?.address.neighborhood ? business?.address.neighborhood : "Jardim Botânico",
									city: business?.address.city ? business?.address.city : "Ribeirão Preto",
									state: business?.address.state,
									zipcode: business?.address.postalCode ? business?.address.postalCode : "14024250",
									country: business?.address.country,
								},
							},
							doc_type: "cpf",
							prit_plan_id: idPlan,
					  },
			});
		}
	}, [me]);

	return (
		<Container
			maxWidth="sm"
			style={{
				background: "#fff",
				borderRadius: 3,
				border: 0,
				color: "white",
				maxWidth: "600px",
				minHeight: "100vh",
				padding: "0 30px",
				boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
			}}>
			<Header />
			<Typography
				variant="h6"
				align="center"
				color="textSecondary"
				gutterBottom
				style={{ padding: "10% 0 0% 0", color: "#393762" }}>
				Informe os dados do seu cartão de crédito
			</Typography>
			<Typography variant="subtitle2" align="center" color="textSecondary" component="p">
				Nesta opção, o pagamento da mensalidade será feito de forma recorrente.
			</Typography>
			<Grid container display="flex" justifyContent="center" alignItems="center">
				<Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
					<img src={CreditsCard} alt="Cartão de crédito" style={{ width: "20%", margin: "5% 0 5% 0" }} />
				</Grid>
				<Grid item xs={12}>
					<Cards cvc={watch("cvc")} expiry={watch("expiry")} name={watch("name")} number={watch("creditCard")} />
					<form
						noValidate
						autoComplete="off"
						style={{
							marginTop: "2rem",
							marginBottom: "2rem",
							display: "flex",
							flexDirection: "column",
							alignContent: "stretch",
							width: "100%",
							gap: "16px",
						}}>
						<Inputs
							control={control}
							name="creditCard"
							type="text"
							label="Número do cartão"
							placeholder="0000 0000 0000 0000"
							inputRef={numberRef}
							handleKeyDown={(e) => handleKeyDown(e, nameRef)}
							inputProps={{
								maxLength: 19,
								pattern: "[0-9]*",
								inputMode: "numeric",
							}}
						/>
						<Inputs
							control={control}
							name="name"
							type="text"
							label="Nome"
							placeholder="Nome como está no cartão"
							inputRef={nameRef}
							handleKeyDown={(e) => handleKeyDown(e, expireRef)}
						/>

						<Inputs
							control={control}
							name="expiry"
							type="text"
							label="Data de validade"
							inputRef={expireRef}
							handleKeyDown={(e) => handleKeyDown(e, cvcRef)}
							inputProps={{
								maxLength: 5,
								pattern: "[0-9]*",
								inputMode: "numeric",
							}}
						/>

						<Inputs
							control={control}
							name="cvc"
							type="text"
							label="CVC"
							inputRef={cvcRef}
							handleKeyDown={(e) => handleKeyDown(e, cpfRef)}
							inputProps={{
								maxLength: 3,
								pattern: "[0-9]*",
								inputMode: "numeric",
							}}
						/>

						<Inputs
							control={control}
							name="cpf"
							type="text"
							label="Documento"
							placeholder="000.000.000-00"
							inputRef={cpfRef}
							handleKeyDown={(e) => handleKeyDown(e, null)}
							inputProps={{
								maxLength: 14,
								pattern: "[0-9]*",
								inputMode: "numeric",
							}}
						/>

						<GlobalButton
							type="button"
							disabled={!isValid}
							title={cardUpdate ? "TROCAR CARTÂO" : "ASSINAR PLANO"}
							variant="contained"
							handleClick={() => fetchPaymentRecurrenceSubscription(state.initialValues)}
							className="btn-color-primary btn-default"
							fullWidth={true}
						/>
					</form>
				</Grid>
			</Grid>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description">
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						bgcolor: "background.paper",
						border: "none",
						boxShadow: 24,
						borderRadius: "12px",
						pt: 2,
						px: 4,
						pb: 3,
						overflow: "auto",
						"&:focus": {
							outline: "none",
						},
						width: isMobile() ? "100%" : "35%",
					}}>
					<Typography id="parent-modal-title" style={{ textAlign: "center", color: "#393762" }}>
						{error ? "Falha no pagamento" : "Assinatura OK"}
					</Typography>
					<p id="parent-modal-description" style={{ textAlign: "center" }}>
						{message}
					</p>
					<div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "16px" }}>
						<GlobalButton
							type="button"
							title="OK"
							variant="contained"
							handleClick={() => handleClose()}
							className="btn-color-primary btn-default"
							fullWidth={true}
						/>
						{message === "O recebedor já tem assinatura ativa" && (
							<GlobalButton
								type="button"
								title="Ir para agenda"
								variant="outlined"
								handleClick={() => navigate("/schedule")}
								className="btn-outlined-secondary btn-default"
								fullWidth={true}
							/>
						)}
					</div>
				</Box>
			</Modal>
		</Container>
	);
};
export default PaymentCredit;
