import React from "react";
import CoreContainer from "../../common/new/container";
import { Close } from "@material-ui/icons";
import { Dialog } from "@mui/material";
import Text from "../../textComponent/text.jsx";
import { useNavigate } from "react-router";
import { auth } from "services/firebase";
import { logoutUser } from "../../../services/loginService";
import { Button } from "components";

const LogoutDialog = ({ isOpen, handleClose }) => {
	const navigate = useNavigate();

	const handleConfirmLogout = () => {
		logoutUser(auth);
		localStorage.clear();
		navigate("/welcome");
	};

	return (
		<Dialog
			onClose={handleClose}
			open={isOpen}
			fullScreen
			PaperProps={{
				style: { backgroundColor: "transparent" },
			}}>
			<CoreContainer style={{ gap: "16px" }} transparent noHeight>
				<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
					<Text style={{ color: "#616161" }}>Deseja sair da sua conta?</Text>
					<Close style={{ color: "#3E3E3E", cursor: "pointer" }} onClick={() => handleClose()}></Close>
				</div>
				<div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
					<Button
						type="button"
						variant="contained"
						className="btn-color-primary btn-default"
						title="Sim"
						handleClick={() => handleConfirmLogout()}
						fullWidth
					/>

					<Button
						type="button"
						variant="outlined"
						className="btn-outlined-secondary btn-default"
						title="Não"
						handleClick={() => handleClose()}
						fullWidth
					/>
				</div>
			</CoreContainer>
		</Dialog>
	);
};

export default LogoutDialog;
