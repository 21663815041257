import React from "react";
import BusinessProfile from "containers/businessProfile";
import OpeningHours from "../../containers/openingHours";
import ListClients from "../../components/listClients";
import Services from "../../components/services";
import Professionals from "../../components/professionals";
import SchedulePlanPages from "../../views/SchedulePlanPages";
import { PaymentProvider } from "context/payment.context";

export default function SchedulePages({ type }) {
	if (type === "businessProfile") return <BusinessProfile />;
	if (type === "openingHours") return <OpeningHours />;
	if (type === "listClients") return <ListClients />;
	if (type === "services") return <Services />;
	if (type === "profilePritPlan")
		return (
			<PaymentProvider>
				<SchedulePlanPages />
			</PaymentProvider>
		);
	if (type === "professionals") return <Professionals />;

	return null;
}
