import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  title: {
    color: "#393762",
    lineHeight: "0.9",
    letterSpacing: "-0.66px",
  },
  container: {
    margin: "auto",
    textAlign: "center",
    width: "50%",
    padding: "10px",
  },
  field: {},
  error: {
    color: "#ff3b30",
    fontSize: "15px",
  },
  icon: {
    cursor: "pointer",
    color: "#3689ea",
    transform: "scale(2)",
  },
  message: {
    color: "rgba(71, 71, 71, 0.3)",
    fontSize: "15px",
    paddingBottom: 10,
  },
  coreContainer: {
    gap: "16px",
    flexWrap: "wrap",
    height: "auto",
    minHeight: "600px",
  },
  appBarWrapper: {
    display: "flex",
    alignContent: "center",
    width: "100%",
  },
  helpButtonStyle: {
    marginTop: "1rem",
  },
  dialogPaper: {
    borderRadius: "20px",
    maxWidth: "400px",
  },
  buttonOK: {
    padding: "15px",
    fontSize: "calc(0.5vw + 10px)",
  },
  inputBox: {
    display: "flex",
    flexDirection: "column",
    alignContent: "stretch",
    width: "100%",
    gap: "16px",
  },
  phoneInputBox: {
    display: "flex",
    gap: "16px",
    width: "100%",
  },
  phoneInput: {
    flex: 1,
    fontFamily: "Poppins",
  },
  textMessage: {
    color: "#9C9C9C",
    textAlign: "center",
  },
  emptyFlexBox: {
    flex: 1,
  },
}));
