import * as Yup from "yup";

export const addressSchema = Yup.object({
	zipcode: Yup.string().required("CEP é obrigatório"),
	address: Yup.string().required("Logradouro é obrigatório"),
	number: Yup.string().required("Número é obrigatório"),
	complement: Yup.string().required("Complemento é obrigatória"),
	uf: Yup.string().required("UF é obrigatório"),
	city: Yup.string().required("Cidade é obrigatória"),
	neighborhood: Yup.string().required("Bairro é um campo obrigatório"),
});

export const initialAddressForm = {
	zipcode: "",
	address: "",
	number: "",
	complement: "",
	uf: "",
	city: "",
	neighborhood: "",
};
