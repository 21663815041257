import api from './api';


export const storeProfessional = (body) => {
    return api.post("/professional", body);
};

export const updateProfessional = (params, body) => {
    return api.patch(`/professional/${params}`, body);
};

export const professionalService = (params, body) => {
    return api.put(`/professional/${params}/professional_service`, body);
};

export const getGetProfessionalService = (params) => {
    return api.get(`professional_service/last_update?professional_id[]=${params}&grouped=false&language=portuguese`);
};

const professialService = {
    storeProfessional,
    updateProfessional,
    professionalService
};

export default professialService;
